import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ProjectItem, TimelineItem } from '../../globalTypes/objects';
import { useToken } from '../../useToken';
import Select from "react-select";
import { useAppSelector } from '../../store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import moment from 'moment';
import { API_BASE_URL } from '../../urlConfig';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
//import UploadProjectFile from './UploadProjectFile';
import garbageIcon from '../../Images/navyGarbageIcon.svg';
import clockIcon from '../../Images/clockIcon.svg';
import greyClockIcon from '../../Images/greyClockIcon.svg';
import hideTitleIcon from '../../Images/hideTitleIcon.svg';
import hideContactIcon from '../../Images/hideContactIcon.svg';
import { forEach } from 'jszip';
import EditContacts from './EditContacts';
import { Department, UserModel } from '../../types';
import { AddContactDiv, EditContactDeleteCell, EditContactDepartmentCell, EditContactNameCell, EditContactRow, EditContactTable } from './ContactsStyles';
import ContactDepartmentGrid from './ContactDepartmentGrid';
import { Draggable } from 'react-drag-reorder';

type Props = {
    projectId?: number | null;
    onSelectedProjectChange?: (newType: number | null) => void;
    onHESDueDateChange?: (newType: any) => void;
}


const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 250, backgroundColor: 'white',
        marginTop: 10, marginRight: 10
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 56,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),

};


interface Option {
    value: string;
    label: string;
}

const ContactsImport = ({ projectId, onSelectedProjectChange, onHESDueDateChange }: Props) => {

    const [successAddDepartment, setSuccessAddDepartment] = useState(false);
    const [successAddUserDepartment, setSuccessAddUserDepartment] = useState(false);
    const [successDeleteDepartment, setSuccessDeleteDepartment] = useState(false);
    const [successUpdateDepartment, setSuccessUpdateDepartment] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [user, setUser] = useState<UserModel | null>(null);
    const [selectedUser, setSelectedUser] = useState<Option | null>(null);
    const [selectedDepartment, setSelectedDepartment] = useState<Option | null>(null);

    //const [department, setDepartment] = useState<{ departmentId: number, department: string }>();
    const [titleError, setTitleError] = useState('');
    const [contactError, setContactError] = useState('');
    const [contactsType, setContactsType] = useState('');
    const [manageType, setManageType] = useState('');
    const [showTitle, setShowTitle] = useState('');
    const [showDepartment, setShowDepartment] = useState('');
    const [newContactType, setNewContactType] = useState('');
    const [loading, setLoading] = useState(false);
    const [seed, setSeed] = useState(1);


    const [schoolContactList, setSchoolContactList] = useState<UserModel[]>([]);
    const [generalContactList, setGeneralContactList] = useState<UserModel[]>([]);
    const [titleList, setTitleList] = useState<Department[]>([]);
    const [titleText, setTitleText] = useState('');
    const [userOptions, setUserOptions] = useState<Option[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<Option[]>([]);

    const getToken = useToken();
    //const localToken =  getToken();
    moment.locale('en-us');

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;





    const AddDepartment = async () => {
        const localToken = await getToken();
        const sortOrderId = titleList.length + 1;
        if (checkIsTitleValid()) {
            setTitleError('');
            axios.post(`${API_BASE_URL}/api/Department/AddDepartment/${titleText}/${showDepartment}/${showTitle}/${sortOrderId}`, {}, {
                headers:
                {
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                },
            })
                .then(async (res) => {
                    //   setDepartmentOptions([]);  
                    await handleReset();
                    getTitles().then(() => {
                        setSuccessAddDepartment(true);
                        setLoading(false);
                        setSeed(Math.random);

                    }
                    )

                })
                .catch(error => {
                    setTitleError(error.response.data);
                    setLoading(false);
                })
        }
        else {
            setTitleError("Please enter a valid Title and select Title options");
        }
    };

    const DeleteDepartment = async (departmentId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Department/DeleteDepartment/${departmentId}`, {}, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            },
        })
            .then((res) => {

                getTitles().then(() => {
                    setSuccessDeleteDepartment(true);
                    setLoading(false);
                    setSeed(Math.random);
                }
                )
            })
            .catch(error => {
                setTitleError(error.response.data);
                setLoading(false);
            })
    };


    const DeleteSchoolUserDepartment = async (schoolUserDepartmentId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Department/DeleteSchoolUserDepartment/${schoolUserDepartmentId}`, {}, {
            headers:
            {
                //'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localToken?.accessToken}`
            },
        })
            .then((res) => {

                setSuccessDeleteDepartment(true);
                setLoading(false);
                getSchoolContacts();
                handleReset();
            })
            .catch(error => {
                setTitleError(error.response.data);
                setLoading(false);
            })
    };

    const DeleteGeneralUserDepartment = async (generalUserDepartmentId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Department/DeleteGeneralUserDepartment/${generalUserDepartmentId}`, {}, {
            headers:
            {
                //'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localToken?.accessToken}`
            },
        })
            .then((res) => {

                setSuccessDeleteDepartment(true);
                setLoading(false);
                getGeneralContacts();
                handleReset();
            })
            .catch(error => {
                setTitleError(error.response.data);
                setLoading(false);
            })
    };

    const UpdateDepartmentAsync = async (departmentId: number, sortOrderId: number, snoozeDepartment: boolean) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/Department/UpdateDepartment/${departmentId}/${sortOrderId}/${snoozeDepartment}`, {}, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(async () => {
                //  console.log(response);
                //  setSuccess(true);
                getTitles().then(() => {

                    setLoading(false);
                    setSuccessUpdateDepartment(true);
                    setSeed(Math.random);
                }
                )



            })
            .catch(error => {
                // setResponse(error?.response?.data);
                setTitleError(error?.response.data);
                setLoading(false);
            })
    };

    const UpdateDepartmentAsync2 = async (departmentId: number, sortOrderId: number, snoozeDepartment: boolean) => {
        setLoading(true);
        try {
            const localToken = await getToken();
            const response = await axios.post(`${API_BASE_URL}/api/Department/UpdateDepartment/${departmentId}/${sortOrderId}/${snoozeDepartment}`, {}, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                },
            });
            await getTitles();

            setTitleList(prevTitleList =>
                prevTitleList.map(dep =>
                    dep.departmentId === departmentId
                        ? { ...dep, snoozed: snoozeDepartment } // Return a new object
                        : dep
                )
            );

            setSuccessUpdateDepartment(true);

        }
        catch (error: any) {
            if (error.response) {
                // Server responded with an error
                setTitleError(error.response.data);
            } else {
                // Network or other error
                setTitleError("An unexpected error occurred.");
            }
        }
        finally {
            setLoading(false);
        }



    };



    const getSchoolContacts = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/department/getSchoolContacts/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });

            //   data.sort((a: { department: Department; }, b: { department: Department; }) => (a?.department?.sortorderid ?? 0) - (b?.department?.sortorderid ?? 0));
            setSchoolContactList(data);
            setLoading(false);
            setContactError('');
        } catch (error: any) {
            setContactError(error.message);
            setLoading(false);
        }
    };
    const getGeneralContacts = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/department/getGeneralContacts`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });

            //   data.sort((a: { department: Department; }, b: { department: Department; }) => (a?.department?.sortorderid ?? 0) - (b?.department?.sortorderid ?? 0));
            setGeneralContactList(data);
            setLoading(false);
            setContactError('');
        } catch (error: any) {
            setContactError(error.message);

            setLoading(false);
        }
    };
    const getTitles = async () => {
        setLoading(true);
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Department/getDepartments`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });

            setTitleList(data);
            data.map((dep: Department) => {
                const existingDepIdnd = departmentOptions.findIndex(x => x.value == dep.departmentId.toString());
                if (existingDepIdnd < 0) {
                    departmentOptions.push({ value: dep.departmentId.toString() ?? '', label: dep.departmentName ?? "" })
                }

            }
            )
            //   console.log(data);
            setLoading(false);
            setTitleError('');
        } catch (error: any) {
            setTitleError(error.message);
            setLoading(false);
        }
    };


    const getUsers = async () => {
        try {
            const localToken = await getToken();

            const userType = "HES";
            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/getAllUsers/${userType}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            // setUserOptions(data);
            if (userOptions.length < 1) {


                data.map((user: UserModel) => {
                    userOptions.push({ value: user.user_Id ?? '', label: user.name ?? "" })

                });
            }
        } catch (error: any) {
            setContactError(error.message || 'An unknown error occurred');
        }
    };
    const AddUserDepartment = async () => {
        setLoading(true);
        if (selectedUser?.value != '' && selectedUser != undefined && selectedDepartment != undefined && selectedDepartment?.value != "") {
            const localToken = await getToken();
            if (contactsType == 'School') {

                axios.post(`${API_BASE_URL}/api/department/AddSchoolUserDepartment/${selectedUser?.value}/${selectedDepartment?.value}/${schoolState.id}`, {}
                    , {

                        headers:
                        {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    })
                    .then(() => {
                        setSuccessAddUserDepartment(true);
                        setContactError('');
                        handleReset();
                        getSchoolContacts();
                        setLoading(false);
                    })
                    .catch(error => {
                        setSuccessAddUserDepartment(false);
                        setContactError("Error while adding user title " + error?.response?.data);
                        setLoading(false);
                    })

            } else {
                axios.post(`${API_BASE_URL}/api/department/AddGeneralUserDepartment/${selectedUser?.value}/${selectedDepartment?.value}`, {}
                    , {

                        headers:
                        {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    })
                    .then(() => {
                        setSuccessAddUserDepartment(true);
                        setContactError('');
                        handleReset();
                        getGeneralContacts(); setLoading(false);
                    })
                    .catch(error => {
                        setSuccessAddUserDepartment(false);
                        setContactError("Error while adding user title " + error?.response?.data);
                        setLoading(false);
                    })


            }
        } else {
            setContactError("Please select a User and a Title.");
            setLoading(false);
        }
    };



    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );
    const handleReset = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setSuccessAddUserDepartment(false);
            setSuccessDeleteDepartment(false);
            setSuccessAddDepartment(false);
            setSelectedDepartment(null);
            setSelectedUser(null);
            setTitleText("");
            setShowTitle("");
            setShowDepartment("");
            setLoading(false);
            // setTitleList([]);
            // setDepartmentOptions([]);

        }, 4000);
    };

    const timerRefDep = React.useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );
    const handleResetDeparmt = () => {
        if (timerRefDep.current) {
            clearTimeout(timerRefDep.current);
        }

        timerRefDep.current = setTimeout(() => {
            getTitles();
            setSeed(Math.random);

        }, 4000);
    };

    useEffect(() => {
        getSchoolContacts();
        getGeneralContacts();
        getTitles();
        //  if (userOptions.length < 1) {
        getUsers();
        //  }  


    }, [selectedSchoolId, successDeleteDepartment, seed, loading]);


    //useEffect(() => {
    //    console.log("refreshing...");
    //}, [loading]);

    //useEffect(() => {
    //    location.reload();
    //}, [selectedSchoolId]);
    //// Log the updated titleList whenever it changes
    //useEffect(() => {
    //    console.log("Updated titleList: ", titleList);
    //    setTitleList(titleList);
    //}, [ seed]);  // This will run every time titleList is updated

    const handleContactsTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactsType((event.target as HTMLInputElement).value);


    };
    const handleManageTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setManageType((event.target as HTMLInputElement).value);
        setContactsType('');
        if ((event.target as HTMLInputElement).value == "Contacts") {
            //  getGeneralContacts();
            //  getSchoolContacts();
            getTitles();
        }
    };

    const handleNewContactTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewContactType((event.target as HTMLInputElement).value);

    };

    const handleShowTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowTitle((event.target as HTMLInputElement).value);

    };
    
    const handleShowDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDepartment((event.target as HTMLInputElement).value);

    };

    const SaveOrderChange = () => {
        //const currentDep = titleList[currPostion];//.sortorderid = newPosition;
        //UpdateDepartment(currentDep.departmentId, newPosition, currentDep.snoozed ?? false);
        const elements = document.getElementsByClassName("depName");

        // Loop through the elements
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i].textContent;

            // Do something with the element
            //   console.log(element);
            const currentDeparment = titleList.find(x => x.departmentName == element);
            if (currentDeparment) {
                currentDeparment.sortOrderId = i;
                UpdateDepartmentAsync(currentDeparment.departmentId, i, currentDeparment.snoozed ?? false);
            }


        }
        //titleList.map((dep, ind) => {
        //    UpdateDepartment(dep.departmentId, ind , dep.snoozed ?? false);

        //});
    };

    const checkIsTitleValid = () => {
        //  const validUpdateType = contactsType != '';
        const validTitle = titleText != '';
        const validShowHide = showTitle != '';
        const validShowHideDepartment = showDepartment != '';
        //const validNewContactType = newContactType != '';
        if (validTitle && validShowHide && validShowHideDepartment) { return true; }
        else { return false; }
    };

    const handleUserChange = (option: Option | null) => {
        setSelectedUser(option);
    };


    const handleDepartmentChange = (option: Option | null) => {
        setSelectedDepartment(option);
    };


    return (
        <div style={{ width: '1200px', marginTop: "15px" }}>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={manageType}
                    onChange={handleManageTypeChange}
                >
                    <FormControlLabel value="Contacts" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="Manage Contacts" />
                    <FormControlLabel value="Title" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="Manage Titles" />

                </RadioGroup>

                {manageType == "Contacts" && < RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={contactsType}
                    onChange={handleContactsTypeChange}
                >
                    <FormControlLabel value="School" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="School Contacts" />
                    <FormControlLabel value="General" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="General Contacts" />

                </RadioGroup>}


                {manageType == "Contacts" && contactsType == "School" && <div>
                    <h4>School Contacts</h4>
                    <EditContactTable>  {schoolContactList.filter((x) => x.department).map(contact => (
                        <EditContactRow>
                            <EditContactNameCell>{contact.firstName} {contact.lastName}
                            </EditContactNameCell>
                            <EditContactDepartmentCell>{contact.department?.departmentName}
                            </EditContactDepartmentCell>
                            <EditContactDeleteCell>
                                <img style={{ cursor: 'pointer' }} title="Delete School Contact" onClick={() => { DeleteSchoolUserDepartment(contact.department?.schoolUserDepartmentsId ?? 0) }} height='15px' width='15px' src={garbageIcon} />
                            </EditContactDeleteCell>
                        </EditContactRow>
                    ))}</EditContactTable>
                    {successDeleteDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Contact successfully deleted!
                    </div>}

                </div>}

                {manageType == "Contacts" && contactsType == "General" && <div>
                    <h4>General Contacts</h4>
                    <EditContactTable>  {generalContactList.filter((x) => x.department).map(contact => (
                        <EditContactRow>
                            <EditContactNameCell>{contact.firstName} {contact.lastName}
                            </EditContactNameCell>
                            <EditContactDepartmentCell>{contact.department?.departmentName}
                            </EditContactDepartmentCell>
                            <EditContactDeleteCell>   <img style={{ cursor: 'pointer' }} title="Delete General Contact" onClick={() => { DeleteGeneralUserDepartment(contact.department?.generalUserDepartmentsId ?? 0) }} height='15px' width='15px' src={garbageIcon} /></EditContactDeleteCell>
                        </EditContactRow>
                    ))}</EditContactTable>

                    {successDeleteDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Contact successfully deleted!
                    </div>}

                </div>}
                {manageType == "Contacts" && (contactsType) && <>
                    <h4>Add {contactsType} Contact</h4>
                    <AddContactDiv>
                        <Select
                            value={selectedUser}
                            options={userOptions}
                            onChange={handleUserChange}
                            styles={customStyles}
                            isClearable={true}
                            placeholder="Select User"
                        />

                        <Select
                            value={selectedDepartment}
                            options={departmentOptions}
                            onChange={handleDepartmentChange}
                            styles={customStyles}
                            isClearable={true}
                            placeholder="Select Title"
                        />

                    </AddContactDiv>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={newContactType}
                        onChange={handleNewContactTypeChange}
                    >

                    </RadioGroup>
                    <Button variant="contained" disabled={loading || (successAddUserDepartment)} style={{ backgroundColor: '#253872', height: '34px', marginTop: '12px', width: '150px' }} onClick={() => (AddUserDepartment())}>Add Contact</Button>
                    {successAddUserDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Contact successfully added!
                    </div>}

                    {contactError &&
                        <div style={{ color: '#FB4A4A', marginTop: '8px' }}>
                            {contactError}
                        </div>
                    }

                </>}

                {manageType == "Title" && <div>
                    <h4>Manage Titles</h4>
                    {/*           <ContactDepartmentGrid departmentList={titleList} />*/}


                    <div className="flex-container" style={{ width: '800px' }}>
                        <div className="row">
                            <Draggable key={seed} >
                                {titleList.map((dep, idx) => {
                                    //   console.log(dep);
                                    // let isSnoozed = dep.snoozed;
                                    return (
                                        <EditContactRow key={idx} className="flex-item">
                                            <EditContactNameCell > <span className="depName">{dep.departmentName}</span></EditContactNameCell>

                                            <EditContactDeleteCell>
                                                {dep.hideDepartment && <img title="Contact and Title Hidden" height='18px' width='18px' src={hideContactIcon} />}
                                            </EditContactDeleteCell>


                                            <EditContactDeleteCell>
                                                {dep.hideDepartmentTitle && <img title="Title Hidden" height='18px' width='18px' src={hideTitleIcon} />}
                                            </EditContactDeleteCell>
                                            <EditContactDeleteCell>   <img style={{ cursor: 'pointer' }} title="Delete Title" onClick={() => {
                                                DeleteDepartment(dep.departmentId ?? 0)
                                            }} height='15px' width='15px' src={garbageIcon} /></EditContactDeleteCell>
                                            <EditContactDeleteCell

                                            //  getTitles();
                                            >      <img style={{ cursor: 'pointer' }} onClick={() => {
                                                const newSnoozeStatus = !(dep.snoozed);
                                                UpdateDepartmentAsync(dep.departmentId ?? 0, dep.sortOrderId ?? 0, newSnoozeStatus);
                                                // handleResetDeparmt();
                                                //// Optionally, you can directly update the list locally:
                                                //setTitleList(prevTitleList =>
                                                //    prevTitleList.map(d =>
                                                //        d.departmentId === dep.departmentId
                                                //            ? { ...d, snoozed: newSnoozeStatus }
                                                //            : d
                                                //    )
                                                //);
                                                //  isSnoozed = newSnoozeStatus;
                                                //  console.log(newSnoozeStatus);
                                            }}
                                                    title="Snooze Title" height='18px' width='18px' src={dep.snoozed ? greyClockIcon : clockIcon} /></EditContactDeleteCell>

                                        </EditContactRow>
                                    );
                                })}
                            </Draggable>
                        </div>
                    </div>


                    {successDeleteDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Title successfully deleted!
                    </div>}
                    {successUpdateDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Title successfully updated!
                    </div>}


                    <Button onClick={() => SaveOrderChange()} variant="contained" disabled={loading} style={{ backgroundColor: '#253872', marginTop: '20px' }}>Save</Button>


                    <h4>Add Title</h4>
                    <TextField style={{ paddingBottom: '15px' }} id="outlined-basic" placeholder="Title Text" variant="outlined" value={titleText} onChange={(e) => setTitleText(e.target.value)} />

                    < RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={showDepartment}
                        onChange={handleShowDepartmentChange}
                    >
                        <FormControlLabel value="show" control={<Radio size={'small'} sx={{
                            color: "#D9D9D9",
                            '&.Mui-checked': {
                                color: "#253872",
                            },
                        }} />} label="Show Contact and Title" />
                        <FormControlLabel value="hide" control={<Radio size={'small'} sx={{
                            color: "#D9D9D9",
                            '&.Mui-checked': {
                                color: "#253872",
                            },
                        }} />} label="Hide Contact and Title" />

                    </RadioGroup>

                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={showTitle}
                        onChange={handleShowTitleChange}
                    >
                        <FormControlLabel value="show" control={<Radio size={'small'} sx={{
                            color: "#D9D9D9",
                            '&.Mui-checked': {
                                color: "#253872",
                            },
                        }} />} label="Show Title" />
                        <FormControlLabel value="hide" control={<Radio size={'small'} sx={{
                            color: "#D9D9D9",
                            '&.Mui-checked': {
                                color: "#253872",
                            },
                        }} />} label="Hide Title" />

                    </RadioGroup>

                    <Button onClick={AddDepartment} variant="contained" disabled={loading || (successAddDepartment)} style={{ backgroundColor: '#253872', marginTop: '20px' }}>Add Title</Button>

                    {successAddDepartment && <div style={{ color: '#72E889', marginTop: '8px' }}>
                        Title successfully added!
                    </div>}



                    {titleError &&
                        <div style={{ color: '#FB4A4A', marginTop: '8px' }}>
                            {titleError}
                        </div>
                    }

                </div>}
                {/*{contactsType == "School" && <EditContacts contactList={contactList} schoolId={schoolState.id} />}*/}

            </FormControl>



            {/*{checkIsProjectValid() && (*/}
            {/*    <div style={{ paddingTop: '10px' }}>*/}
            {/*        <Button onClick={handleUpload} variant="contained" disabled={loading || (success && uploadSuccess)} style={{ backgroundColor: '#253872' }}>*/}
            {/*            {projectType == "New"? "Create" : "Update"}*/}

            {/*        </Button>*/}
            {/*    </div>*/}
            {/*)}*/}





        </div>

    );
};

export default ContactsImport;

