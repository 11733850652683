import React, { useState } from 'react';
import styled from '@emotion/styled';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import noDownloadIcon from '../../Images/GreyDownloadIcon.svg';
import axios from 'axios';
import saveAs from 'file-saver';
import { useToken } from '../../useToken';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    documentIds: string;
    filePath: string;
    fileName: string;
    projectId: number;
    schoolId: number | null;
    projectDescription: string;
}


const Wrapper = styled.div`
cursor:pointer;
padding-left:20px`;

const WrapperNoDownload = styled.div`
padding-left:20px`;
const UploadTextNoDownload = styled.div`
color: #B1B1B1;
font-size:11px; 
margin-left: 5px;
`;

const Icon = styled.img`
width: 25px;

max-width: 100%;
overflow: hidden;
height: 25px;
margin-top: 3px;
margin-left: 20px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:11px; 
margin-left: 5px;
`;

const SchoolDownloadIcon = ({ documentIds, filePath,fileName, projectId, schoolId, projectDescription }: Props) => {


    const getToken = useToken();

    const handleFormDownload = async () => {
        try {

            const documentIdArrray = documentIds.split(',');
            const fileNameArray = fileName.split(',');
            const filePathArray = filePath.split(',');
            for (let i = 0; i < documentIdArrray.length; i++) {

                const localToken = await getToken();
                //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
                logDownloadForm(documentIdArrray[i]);

                const response = await axios.get(`${API_BASE_URL}/api/Document/DownloadProjectDocument/${documentIdArrray[i]}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                });


                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const filePathCurrent = filePathArray[i];
                const fileExt = filePathCurrent.substring(filePathCurrent.lastIndexOf('.'));
                const fileName = fileNameArray[i] ;//  documentIdArrray.length > 1 ? projectDescription + '_' + (i + 1) + fileExt : projectDescription + fileExt;
                saveAs(blob, fileName);
            }
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };
    const logDownloadForm = async (documentId: string) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadProjectLog/${documentId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };

    return (
        <>
            {(documentIds != null ) && <Wrapper onClick={() => handleFormDownload()}>  <Icon src={downloadIcon} alt="Download Icon" />
                <UploadText>Download</UploadText></Wrapper>}
            {documentIds == null && <WrapperNoDownload >  <Icon src={noDownloadIcon} alt="Download Icon" />
                <UploadTextNoDownload >Downloads</UploadTextNoDownload></WrapperNoDownload>}
        </>
       
    );
};
export default SchoolDownloadIcon;
