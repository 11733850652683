import React, { useState } from 'react';
import styled from '@emotion/styled';
import nextIcon from '../Images/NextIcon.svg';
import greyNextIcon from '../Images/greyNextIcon.svg';
import { IconButton } from '@mui/material';

type Props = {
    onClick: () => void;
    showNext: boolean;
    disableArrow: boolean;
}

const Wrapper = styled.div`
cursor:pointer;
display:flex`;



const Icon = styled.img`
width: 28px;

overflow: hidden;
height: 28px;

`;

const IconText = styled.div`
margin-top: 12px;
padding-left:2px;
padding-right:2px;

`;
const NextIcon = ({ onClick, showNext , disableArrow}: Props) => {

    return (
        <Wrapper>
            {showNext && <IconText onClick={onClick} style={disableArrow ? { color: '#D9D9D9' } : { color: '#253872' }}>Next</IconText>}
        
            <IconButton onClick={onClick} disabled={disableArrow ? true : false}> <Icon src={disableArrow ? greyNextIcon : nextIcon} alt="Next" /></IconButton>

        </Wrapper>
    );
};
export default NextIcon;
