import React, { useContext, useEffect, useState } from 'react';
import { StudentFile, StudentFolder } from '../../globalTypes/objects';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth0 } from "@auth0/auth0-react";
import { Elipse, HeaderElipse, InactiveTag, StudentId, StudentName } from './FoldersStyles';
import '../../styles.css';
import { Box, Card, Checkbox, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import filterIcon from '../../Images/filterIcon.svg';
import Button from '@mui/material/Button';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { useToken } from '../../useToken';
import saveAs from 'file-saver';
import { API_BASE_URL } from '../../urlConfig';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import styledE from "@emotion/styled";
import { FilterText } from '../Projects/ProjectsStyles';
import { MdPrint } from 'react-icons/md';
import { Item } from 'semantic-ui-react';
import JSZip, { folder } from 'jszip';
import printJS from 'print-js';
import FilterModal from './FilterModal';
import TabAcademicStudentFiles from './TabAcademicStudentFiles';
import TabISIRS from './TabISIRS';
import TabAcademicReports from './TabAcademicReports';
import TabFinancialAidReports from './TabFinancialAidReports';
import { School } from '../../types';
import TabAnnualReports from './TabAnnualReports';
import TabMonitoringDocuments from './TabMonitoringDocuments';
import TabTuitionReports from './TabTuitionReports';
import TabTuitionCards from './TabTuitionCards';
import TabAccreditationVisits from './TabAccreditationVisits';
/*import { cocatBlobs, postToServer } from 'ConcatenateBlobs';*/

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    width: '1300px',
    display: 'flex', marginLeft: '24px'
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    //'&:nth-of-type(even)': {
    //    backgroundColor: 'rgba(131, 151, 248, 0.07)',
    //},
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Folders = () => {
    const getToken = useToken();
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;

    const folderOptions = [
        { value: 0, label: "Academic Student Files" },
        { value: 1, label: "Academic Reports" },
        { value: 2, label: "ISIRS" },
        { value: 3, label: "Financial Aid Reports" },
        { value: 4, label: "Tuition Cards" },
        { value: 5, label: "Tuition Reports" },
        { value: 6, label: "Accreditation Visits" },
        { value: 7, label: "Monitoring  Documents" },
        { value: 8, label: "Annual Reports" }
    ]
    const [academicFolders, setAcademicFolders] = useState<StudentFolder[]>([]);
    const [isirFolders, setIsirFolders] = useState<StudentFolder[]>([]);
    const [academicReportFolders, setAcademicReportFolders] = useState<StudentFolder[]>([]);
    const [accreditationVisitsFolders, setAccreditationVisitsFolders] = useState<StudentFolder[]>([]);
    const [financialAidReportsFolders, setFinancialAidReportsFolders] = useState<StudentFolder[]>([]);
    const [tuitionCardsFolders, setTuitionCardsFolders] = useState<StudentFolder[]>([]);
    const [tuitionReportFolders, setTuitionReportFolders] = useState<StudentFolder[]>([]);
    const [monitoringDocumentsFolders, setMonitoringDocumentsFolders] = useState<StudentFolder[]>([]);
    const [annualReportFolders, setAnnualReportFolders] = useState<StudentFolder[]>([]);
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [folderType, setFolderType] = useState<any>(folderOptions.find(x => x.value == 0));
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [allChecked, setAllChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [seed, setSeed] = useState(1);
    const [schoolAccreditationAgency, setSchoolAccreditationAgency] = useState('');



    const handleTabChange = (index: number) => {
        setFolderType(folderOptions.find(x => x.value == index));
    };
    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, studentId: number) => {
        const updatedFolders = [...academicFolders];
        updatedFolders.forEach((folder) => {
            if (folder.externalStudentId === studentId) {
                folder.isChecked = event.target.checked;
            }
        });
        setAcademicFolders(updatedFolders);
        const isEveryBoxChecked = academicFolders.every((folder) => folder.isChecked);
        setAllChecked(isEveryBoxChecked);

    };
    const handleAllCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
        const allFolders = [...academicFolders];
        allFolders.forEach((folder) => {

            folder.isChecked = event.target.checked;

        });
        setAcademicFolders(allFolders);
    };

    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "Alpha":
                    setAcademicFolders(academicFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setSeed(Math.random());
                    break;
                case type = "StudentId":
                    setAcademicFolders(academicFolders.sort((a, b) => a.externalStudentId - b.externalStudentId));
                    setSeed(Math.random());
                    break;
                case type = "AllStudents":
                    setShowActive(true);
                    setShowInactive(true);
                    setSeed(Math.random());
                    break;
                case type = "ActiveStudents":
                    setShowInactive(false);
                    setShowActive(true);
                    setSeed(Math.random());
                    break;
                case type = "InactiveStudents":
                    setShowInactive(true);
                    setShowActive(false);
                    setSeed(Math.random());
                    break;
                case type = "Reset":
                    setAcademicFolders(academicFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setShowInactive(false);
                    setShowActive(true);
                    setSeed(Math.random());
                    break;
            }
        });
        setLoading(false);
    };

    useEffect(() => {

        getSchool().then(() => {
            if (schoolAccreditationAgency == "AARTS") {
                loadData("Monitoring Documents").then((data) =>
                    setMonitoringDocumentsFolders(data));
            }
            else { //AIJS
                loadData("Annual Report").then((data) =>
                    setAnnualReportFolders(data));
            }
        });

        loadData("Academic Student Files").then((data) =>
            setAcademicFolders(data));

        loadData("ISIRS").then((data) =>
            setIsirFolders(data));

        loadData("Academic Reports").then((data) =>
            setAcademicReportFolders(data));

        loadData("Financial Aid Reports").then((data) =>
            setFinancialAidReportsFolders(data));

        loadData("Accreditation Visits").then((data) =>
            setAccreditationVisitsFolders(data));

        loadData("Monitoring Documents").then((data) =>
            setMonitoringDocumentsFolders(data));

    }, [selectedSchoolId]);

    useEffect(() => {
        console.log('refresh');
    }, [schoolAccreditationAgency, selectedSchoolId]);

    const loadData = async (folderCategory: string) => {
        let data: StudentFolder[] = [];
        setLoading(true);
        try {
            const localToken = await getToken();
            await axios.get<StudentFolder[]>
                (`${API_BASE_URL}/api/Folders/getAllFolders/${selectedSchoolId}/${folderCategory}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}`}
                    }).then((res) => {
                        data = res.data;
                        return res.data;

                    })
                .catch(error => {
                    console.log('Error occured while fetching ' + folderCategory + ' Folders');

                });

            setLoading(false);

        } catch ({ message }) {
            setError('An unknown error occurred' || message);
            setLoading(false);
        }
        return data;
    };



    const getSchool = async () => {
        setLoading(true);
        try {
            const localToken = await getToken();
            await axios.get<School>
                (`${API_BASE_URL}/api/School/getSchool/${selectedSchoolId}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    }).then((res) => {
                        setSchoolAccreditationAgency(res.data.accreditationAgency ?? '');
                       // return res.data;

                    })
                .catch(error => {
                    console.log(error?.response?.data);

                });

            setLoading(false);

        } catch ({ message }) {
            setError('An unknown error occurred' || message);
            setLoading(false);
        }
      
    };


    const tabStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        listStyleType: 'none',
        borderBottom: ' 0px solid #fff',
        backgroundColor: 'transparent', // make sure there's no background
        outline: 'none', // remove default outline
    };

    const activeTabStyle = {
        ...tabStyle,
        borderBottom: '3px solid',
        borderRadius: '2px',


    };

    return (
        <>

            {selectedSchoolName && (
                <>
                    <div className="pageHeader">School Files</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}></div>
                    <Box sx={{ marginTop: '30px', width: '90%', maxWidth: '1400px', minHeight: '300px', overflowX: 'auto', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)' }}>

                        <Box>
                            <Tabs onSelect={handleTabChange}>
                                <TabList style={{ display: 'flex' }} >
                                    {/*<Tab className='faTabColor' style={folderType?.value === 0 ? activeTabStyle : tabStyle}>Financial Aid</Tab>*/}
                                    <Tab className='academicTabColor' style={folderType?.value === 0 ? activeTabStyle : tabStyle}>Academic Student Files</Tab>
                                    <Tab className='academicTabColor' style={folderType?.value === 1 ? activeTabStyle : tabStyle}>Academic Reports</Tab>
                                    <Tab className='faTabColor' style={folderType?.value === 2 ? activeTabStyle : tabStyle}>ISIRS</Tab>
                                    <Tab className='faTabColor' style={folderType?.value === 3 ? activeTabStyle : tabStyle}>Financial Aid Reports</Tab>
                                    <Tab className='tuitionTabColor' style={folderType?.value === 4 ? activeTabStyle : tabStyle}>Tuition Cards</Tab>
                                    <Tab className='tuitionTabColor' style={folderType?.value === 5 ? activeTabStyle : tabStyle}>Tuition Reports</Tab>
                                    <Tab className='viewAllTabColor' style={folderType?.value === 6 ? activeTabStyle : tabStyle}>Accreditation Visits</Tab>
                                        <Tab className='viewAllTabColor' style={folderType?.value === 7 ? activeTabStyle : tabStyle}>
                                        {schoolAccreditationAgency && schoolAccreditationAgency == "AARTS" &&
                                            <span> Monitoring Documents</span>} {schoolAccreditationAgency && schoolAccreditationAgency == "AIJS" &&
                                                <span>Annual Report</span>}
                                        </Tab>
                                   
                                      {/*  <Tab className='viewAllTabColor' style={folderType?.value === 7 ? activeTabStyle : tabStyle}>Annual Report</Tab>}*/}
                                
                                </TabList>
                                <TabPanel>
                                    {academicFolders && academicFolders.length > 0 && <TabAcademicStudentFiles key={selectedSchoolId} studentFolders={academicFolders} />}
                                </TabPanel>
                                <TabPanel>{academicReportFolders && academicReportFolders.length > 0 && <TabAcademicReports key={selectedSchoolId} studentFolders={academicReportFolders} />}</TabPanel>

                                <TabPanel>
                                    {isirFolders && isirFolders.length > 0 && <TabISIRS key={selectedSchoolId} studentFolders={isirFolders} />}
                                </TabPanel>
                                <TabPanel>{financialAidReportsFolders && financialAidReportsFolders.length > 0 && <TabFinancialAidReports key={selectedSchoolId} studentFolders={financialAidReportsFolders} />}</TabPanel>
                              
                                <TabPanel><TabTuitionCards studentFolders={[]}></TabTuitionCards></TabPanel>
                                <TabPanel><TabTuitionReports ></TabTuitionReports></TabPanel>
                                <TabPanel><TabAccreditationVisits></TabAccreditationVisits></TabPanel>
                                <TabPanel>
                                    {schoolAccreditationAgency && schoolAccreditationAgency == "AARTS" &&
                                        <> {monitoringDocumentsFolders && monitoringDocumentsFolders.length > 0
                                            && <TabMonitoringDocuments folderCategory={"Monitoring Documents"} />}</>}

                                    {schoolAccreditationAgency && schoolAccreditationAgency == "AIJS" && <>{annualReportFolders && annualReportFolders.length > 0 
                                        && <TabMonitoringDocuments folderCategory={"Annual Report"} />}</>}
                                </TabPanel>
                            </Tabs>
                        </Box>



                    </Box>
                </>
            )}
        </>
    );
};

export default Folders;

