import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import alertNoticeIcon from '../../Images/noticeAlertIcon.svg';
import completeNoticeIcon from '../../Images/noticeCheckIcon.svg';
import commentNoticeIcon from '../../Images/noticeCommentIcon.svg';
import overdueNoticeIcon from '../../Images/noticeOverdueIcon.svg';
import infoNoticeIcon from '../../Images/informationIcon.svg';
import closeIcon from '../../Images/closeGreyIcon.svg';
import parse from 'html-react-parser';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';
import { useToken } from '../../useToken';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

type Props = {
    noticeText: string;
    notificationId: number;
    notificationDate: string;
    setNotificationCleared: any;
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  width: 40px;
`;

const NoticeText = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  width: 50%;
`;
const NoticeDetails = styled.div`
   margin-top: 20px;
`;
const NoticeExpand = styled.div`
   width: 15%;
`;

const ClearNotice = styled.div`
  margin-top: 8px; 
  margin-left: 10px;
  cursor: pointer;
    width: 5%;
  `;


const NoticeDate = styled.div`
  font-family: 10px; 
  font-size: 14px;
    margin-top: 10px;
  margin-left: 20px;
  color:#7F96FF;
    width: 10%;
  `;





const NotificationItem = ({ noticeText, notificationId, notificationDate, setNotificationCleared }: Props) => {
    const [noticeTitle, setNoticeTitle] = useState<string>('');
    const [noticeDetails, setNoticeDetails] = useState<string>('');
    const [noticePage, setNoticePage] = useState<string>('');
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
    const getToken = useToken();

    const logNotificationClear = async () => {
        const localToken = await getToken();
        //setLoading(true);

        axios.post(`${API_BASE_URL}/api/Notification/LogNotificationCleared/${notificationId}`, {  }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                //  console.log(response);
                //  setSuccess(true);
             //   getFeedback();
                //setLoading(false);
                setNotificationCleared( notificationId);

            })
            .catch(error => {
                // setResponse(error?.response?.data);
              //  setError(error?.response);
               // setLoading(false);
            })
    };


    const formatDate = (dateString: string) => {
        const date = moment(dateString);
        const formatedDate = date.format("MMM DD");
        return formatedDate;

    };

    // Format noticeText into title and details
    useEffect(() => {
        
        const formatNotice = () => {
            const titleParts = noticeText.split('for', 2);
            const title = titleParts[0].trim(); // Keep everything before "for"
            const details = noticeText.split(':<br />', 2)[1]?.trim(); // Split at ':' to extract details

            setNoticeTitle(title);


          //  setNoticeDetails(details ? details.replaceAll('<br />', '').trim() : '');

            // Format the details by replacing <br /> and trimming spaces
            const formattedDetails = details ? details.replaceAll('<br />', '').trim() : '';

            // Regex to find <a> tags and replace them with NavLink components
            const formattedNoticeDetails = formattedDetails.replace(/<a\s+href=([^ >]+)[^>]*>([^<]+)<\/a>/g, (match, href, linkText) => {
                // Extract the last part of the URL after the last slash (/)
                const urlParts = href.split('/');
                const lastSegment = urlParts[urlParts.length - 1]; // Get the last segment (e.g., "folders")

                // Return the NavLink with the last URL part as the 'to' prop
                setNoticePage(lastSegment);
                return `<a href="/${lastSegment}" class="notificationLink"  >${linkText}</a>`;
            });

            setNoticeDetails(formattedNoticeDetails || ''); // Set the formatted details
        };

        if (noticeText) {
            formatNotice();
        }
    }, [noticeText]);

    const toggleDetails = () => {
        setDetailsVisible(prevState => !prevState); // Toggle visibility
    };

    return (
        <Wrapper>
            {noticeText != null && (
                <Icon>
                    {(() => {
                        if (noticeText.indexOf('Complete') > -1) {
                            return <img src={completeNoticeIcon} />;
                        } else if (noticeText.indexOf('Incomplete') > -1) {
                            return <img src={alertNoticeIcon} />;
                        } else if (noticeText.indexOf('Comment') > -1) {
                            return <img src={commentNoticeIcon} />;
                        } else if (noticeText.indexOf('Overdue') > -1) {
                            return <img src={overdueNoticeIcon} />;
                        } else {
                            return <img src={infoNoticeIcon} />;
                        }
                    })()}
                </Icon>

            )}
            <NoticeText>
                <div><strong>{parse(noticeTitle)}</strong></div>
                {detailsVisible && (
                    <NoticeDetails> {parse(noticeDetails)}</NoticeDetails> // Show details only if visible<NavLink to={noticePage } ></NavLink>
                )}

            </NoticeText>
            <NoticeExpand>   <div className="expandAllButton" onClick={toggleDetails}>
                <div className="textWrapper">{detailsVisible ? 'Hide Details' : 'View Details'}
                </div>
            </div></NoticeExpand> 
            <NoticeDate>{formatDate(notificationDate)}</NoticeDate>

            <ClearNotice onClick={logNotificationClear}> <img title={"Clear Notification"} className="closeIcon" alt="close" src={closeIcon} /></ClearNotice> 
        </Wrapper>
    );
};

export default NotificationItem;

function getToken() {
    throw new Error('Function not implemented.');
}
