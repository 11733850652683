import React, { useState } from 'react';
import styled from '@emotion/styled';
import uploadIcon from '../../Images/uploadPurpleIcon.svg';
import FileUploadModal from './FileUploadModal';
import { UploadText } from './FoldersStyles';
import { IconButton } from '@mui/material';


type Props = {
    fileTitle: string;
    fileId: number;
    fileType: string;
    schoolId: number | null;
    onClose: () => void;
}
const Wrapper = styled.div`
margin-top: 10px;
margin-left: 30px;`;




const Icon = styled.img`
width: 30px;

max-width: 100%;
overflow: hidden;
height: 30px;

margin-left: 5px;
`;
const SchoolUploadIcon = ({ fileTitle, fileId, fileType, schoolId, onClose }: Props) => {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const onUploadModalClose = async () => {
        setOpenUploadModal(false);
        onClose();
    }

    return (


        <Wrapper >  <IconButton onClick={() => setOpenUploadModal(true)} style={{ width: '30px', height: '30px', marginLeft: '8px' }}> <img
            src={uploadIcon} alt="Upload Icon"
            style={{ width: '30px', height: '30px' }}
        /></IconButton>
            <UploadText>Upload</UploadText>
            {openUploadModal && <FileUploadModal onClose={onUploadModalClose} schoolId={schoolId} fileTitle={fileTitle} fileId={fileId} fileType={fileType} />}</Wrapper>
    );
};
export default SchoolUploadIcon;
