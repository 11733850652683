import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ProjectItem, TimelineItem } from '../../globalTypes/objects';
import { useToken } from '../../useToken';
import Select from "react-select";
import { useAppSelector } from '../../store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import moment from 'moment';
import { API_BASE_URL } from '../../urlConfig';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import UploadProjectFile from './UploadProjectFile';
import ProjectReportAttachments from './ProjectReportAttachments';
import { forEach } from 'jszip';
import { useAuth0 } from '@auth0/auth0-react';
import { Department } from '../../types';

type Props = {
    projectId?: number | null;
    onSelectedProjectChange?: (newType: number | null) => void;
    onHESDueDateChange?: (newType: any) => void;
}


const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 300, backgroundColor: 'white',
        marginTop: 10
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 56,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),

};

const ProjectReportSelector = ({ projectId, onSelectedProjectChange, onHESDueDateChange }: Props) => {
    const [yearlyOverviews, setYearlyOverviews] = useState<TimelineItem[]>();
    const [existingProjects, setExistingProjects] = useState<ProjectItem[]>();
    const [selectedYO, setSelectedYO] = useState<number>();
    const [selectedYOContactGroup, setSelectedYOContactGroup] = useState<Department|null>();
    const [selectedExistingProject, setSelectedExistingProject] = useState<number>();
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    const [deadlineDate, setDeadlineDate] = React.useState(moment());
    const [hesDueDate, setHesDueDate] = React.useState(moment());
    const [successfulUpload, setSuccessfulUpload] = useState(false);
    const [success, setSuccess] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [error, setError] = useState('');
    const [projectType, setProjectType] = useState('');
    const [associateYO, setAssociateYO] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [projectName, setProjectName] = useState('');
    const [sendNoticeTo, setSendNoticeTo] = useState('createdBy');
    const [allSchools, setAllSchools] = useState('');
    const [createdProjectId, setCreatedProjectId] = useState<number>();
    const [roles, setRoles] = useState<string[] | string>();
    const [schools, setSchools] = useState<{ id: number, name: string }[]>([]);

    const { user } = useAuth0();

    const getToken = useToken();
    //const localToken =  getToken();
    moment.locale('en-us');

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;

    const getProjects = async () => {
        try {
             const localToken = await getToken();
            if (selectedSchoolId) {
                const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getProjects/${selectedSchoolId}`, {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                });
                setYearlyOverviews(data);
            }

        } catch (error: any) {
            setError(error.response.data);
        }
    };


    const getExistingProjects = async () => {
        try {
             const localToken = await getToken();
            if (selectedSchoolId) {
                const { data } = await axios.get(`${API_BASE_URL}/api/Project/getExistingProjects/${selectedSchoolId}`, {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                });
                setExistingProjects(data);
            }

        } catch (error: any) {
            setError(error.response.data);
        }
    };
    async function getSchools(): Promise<{ id: number, name: string }[]> {
        const localToken = await getToken();
        return new Promise((resolve) => {
          
            setLoading(true);
            try {
                fetch(`${API_BASE_URL}/api/School/GetAll`, {
                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        setSchools(data);
                        resolve( data);
                    });
            }
            catch ({ message }) {
                setError('An unknown error occurred' || message);
            } finally {
                setLoading(false);
            }
        });     
    }

    const handleUpload = async () => {

        // if (selectedFiles && selectedFiles.length>0) {
        const localToken = await getToken();
        if (success == false) {
            setLoading(true);
            if (projectType == "New") {

                const selectedYearlyOverview = yearlyOverviews?.find(x => x.id == selectedYO);

                const department = {
                    departmentId: selectedYOContactGroup?.departmentId,
                    departmentName: selectedYOContactGroup?.departmentName,

                };

                const project = {
                    ProjectDescription: associateYO == "No" ? projectName : selectedYearlyOverview?.taskDescription,
                    SchoolId: schoolState.id,
                    TimelineId: selectedYO ?? 0,
                    DeadlineDate: deadlineDate.toDate(),
                    HESDueDate: hesDueDate.toDate(),
                    Status: "To Do",
                    // Department: department,
                    departmentId: sendNoticeTo == 'contactGroup'? selectedYOContactGroup?.departmentId:null,
                    
                };

                //if (allSchools == "Yes") {
                //  const schools =  await getSchools();
                //    schools.forEach(async school => {
                //        project.SchoolId = school.id;
                       
                //        await CreateProject(project);
                //    });


                //}else { }
                await CreateProject(project);
            } else {
                const selectedProject = existingProjects?.find(x => x.projectId == selectedExistingProject);
                const project = {
                    ProjectId: selectedProject?.projectId,
                    ProjectDescription: selectedProject?.projectDescription,
                    SchoolId: selectedProject?.schoolId,
                    TimelineId: selectedProject?.timelineId,
                    DeadlineDate: deadlineDate.toDate(),
                    HESDueDate: hesDueDate.toDate(),
                    Status: selectedProject?.status,
                    departmentId: sendNoticeTo == 'contactGroup' ? selectedYOContactGroup?.departmentId : null,
                };

                axios.post(`${API_BASE_URL}/api/Project/UpdateProject`, project, {
                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    },
                })
                    .then(() => {
                        if (project) {
                            setSuccess(true);
                            setLoading(false);
                            setCreatedProjectId(project.ProjectId);
                            if (project.ProjectId != undefined) UploadProjectDocs(project.ProjectId, schoolState.id);
                            else { handleReset() }
                        }
                    })
                    .catch(error => {
                        setError(error.response.data);
                        setLoading(false);
                    })


            }
        } else {// if project was allready created then just upload the docs
            if (createdProjectId != undefined) UploadProjectDocs(createdProjectId, schoolState.id);
        }
    };


    const CreateProject = async (project: any) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Project/CreateProject`, project, {
            headers:
            {
                //'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localToken?.accessToken}`
            },
        })
            .then((res) => {
                const newProjectId = res.data
                setCreatedProjectId(newProjectId);
                UploadProjectDocs(newProjectId, schoolState.id);
                setSuccess(true);
                setLoading(false);
            })
            .catch(error => {
                setError(error.response.data);
                setLoading(false);
            })
    };

    const UploadProjectDocs = async (createdProjectId: number, schoolId: number) => {
        const localToken = await getToken();
        if (selectedFiles && selectedFiles.length > 0) {
            setLoading(true);
            selectedFiles.map(selectedFile => {


                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);

                axios.post(`${API_BASE_URL}/api/Project/ImportProjectDocuments/${createdProjectId}/${schoolId}`, formData, {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    },
                })
                    .then(() => {
                        setUploadSuccess(true);
                        setLoading(false);
                        setUploadError("");
                        getExistingProjects();
                        handleReset();
                    })
                    .catch(error => {
                        setUploadError(error.response.data);
                        setLoading(false);
                    })
            });
        }
        else {
            setUploadSuccess(true);
            handleReset();
        }
    };

    const setUserRoles = async () => {
        const localToken = await getToken();
        setRoles(localToken?.roles);

    };

    useEffect(() => {
        setUserRoles();
        getProjects();
        getExistingProjects();
    }, [selectedSchoolId]);


    const handleProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectType((event.target as HTMLInputElement).value);
        setSelectedExistingProject(0);
        setSelectedYO(0);
        setProjectName('');
        setSelectedYOContactGroup(null);
        setSendNoticeTo('createdBy');
    };
    const handleAssociateYOChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssociateYO((event.target as HTMLInputElement).value);
        setSelectedYO(0);
        setProjectName('');
        setSelectedYOContactGroup(null);
        setSendNoticeTo('createdBy');
    };

    const handleSendNoticeToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendNoticeTo((event.target as HTMLInputElement).value);
       
    };

    const handleAllSchoolsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllSchools((event.target as HTMLInputElement).value);
    };

    const handleExistingProjectChange = (projectId: number) => {
        setSelectedExistingProject(projectId);
        const selectedProj = existingProjects?.find(x => x.projectId == projectId);
        setDeadlineDate(moment(selectedProj?.deadlineDate));
        setHesDueDate(moment(selectedProj?.hesDueDate));
        const matchingYO = yearlyOverviews?.find(x => x.id == selectedProj?.timelineId);
        if (matchingYO && matchingYO.department) {
            setSelectedYOContactGroup(matchingYO.department);
            if (selectedProj?.department != null) {
                setSendNoticeTo('contactGroup');
            } else {
                setSendNoticeTo('createdBy');
            }
        }
        

    };

    const handleYOChange = (YOId: number) => {
        setSelectedYO(YOId);
        const selectedYO = yearlyOverviews?.find(x => x.id == YOId);
        setDeadlineDate(moment(selectedYO?.deadlineDate));
        setHesDueDate(moment(selectedYO?.deadlineDate));
        setSelectedYOContactGroup(selectedYO?.department);
        setSendNoticeTo('createdBy');
    };
    const checkIsProjectValid = () => {
        const validUpdateType = projectType != '';
        const validProject = selectedYO != 0 || projectName != '' || selectedExistingProject != 0;
        const validDates = deadlineDate && hesDueDate;
        if (validUpdateType && validProject && validDates) { return true; }
        else { return false; }
    };


    const formatTaskDescription = (taskDescription: string) => {
        let taskDescriptionUpdated = taskDescription;

        if (taskDescription.includes("IPEDS")) {
            taskDescriptionUpdated = taskDescriptionUpdated.replace(/\b(opens|closes)\b/, '').trim();
        }
        return taskDescriptionUpdated; 
    };

    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );
    const handleReset = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setSelectedYO(0);
            setSelectedYOContactGroup(null);
            setSelectedExistingProject(0);
            setSelectedFiles(null);
            setDeadlineDate(moment());
            setHesDueDate(moment());
            setSuccessfulUpload(false);
            setSuccess(false);
            setLoading(false);
            setUploadSuccess(false);
            setError('');
            setProjectType('');
            setAssociateYO('');
            setUploadError('');
            setProjectName('');
            setSendNoticeTo('createdBy');
            setAllSchools('');
            setCreatedProjectId(0);
            getExistingProjects();

        }, 4000);
    };
    return (
        <div style={{ width: '1200px', marginTop: "15px" }}>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={projectType}
                    onChange={handleProjectTypeChange}
                >
                    <FormControlLabel value="New" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="Create New" />
                    <FormControlLabel value="Existing" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="Update Existing" />

                </RadioGroup>

                {projectType == "New" && <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={associateYO}
                    onChange={handleAssociateYOChange}
                    sx={{ paddingTop: '12px' }}
                >
                    <Box sx={{ paddingTop: '10px', paddingBottom: '8px', paddingRight: '15px', color: '#253872' }}>Associate Yearly Overview? </Box>
                    <FormControlLabel value="Yes" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio size={'small'} sx={{
                        color: "#D9D9D9",
                        '&.Mui-checked': {
                            color: "#253872",
                        },
                    }} />} label="No" />

                </RadioGroup>}



            </FormControl>



            {projectType == "New" && associateYO == "No" &&
                <div>  <TextField style={{ backgroundColor: '#fff', width: '300px', marginTop: '10px', }} id="outlined-basic" placeholder="Enter Project or Report Name" variant="outlined" onChange={(e) => setProjectName(e.target.value)} />
                </div>}


            {yearlyOverviews && projectType == "New" && associateYO == "Yes" &&
           <>     <Select
                options={yearlyOverviews?.map(s => (

                    {
                        label: formatTaskDescription( s.taskDescription) + " (" + s.deadlineMonth + ")",
                        value: s.id,
                    }))}
                    onChange={(event) => handleYOChange(event?.value ?? 0)}
                    styles={customStyles}
                    placeholder="Select Project or Report"
            />

                




            </>
            }


            {projectType == "Existing" && <Select
                options={existingProjects?.map(s => ({
                    label: s.projectDescription,
                    value: s.projectId,
                }))}
                onChange={(event) => handleExistingProjectChange(event?.value ?? 0)}
                styles={customStyles}
                placeholder="Select Existing Project or Report"
            />
            }


            {((selectedExistingProject != undefined && selectedExistingProject != 0) || (selectedYO != undefined && selectedYO != 0) || (associateYO == "No"))

                && <div style={{ marginTop: '15px' }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}  >
                        <DatePicker label="Deadline Date" sx={{ backgroundColor: '#fff', borderColor: '#e5e5ea', width: '300px', marginTop: '10px' }}
                            value={deadlineDate} onChange={(newValue) => setDeadlineDate(newValue ?? moment())} />

                    </LocalizationProvider></div>}

            {((selectedExistingProject != undefined && selectedExistingProject != 0) || (selectedYO != undefined && selectedYO != 0) || (associateYO == "No"))
                && <div style={{ marginTop: '15px' }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}  >
                        <DatePicker label="Hes Due Date" sx={{ backgroundColor: '#fff', borderColor: '#e5e5ea', width: '300px', marginTop: '10px' }}
                            value={hesDueDate} onChange={(newValue) => setHesDueDate(newValue ?? moment())} />

                    </LocalizationProvider></div>}


            {selectedYOContactGroup != null && <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={sendNoticeTo}
                onChange={handleSendNoticeToChange}
                sx={{ paddingTop: '12px' }}
            >
                <Box sx={{ paddingTop: '10px', paddingBottom: '8px', paddingRight: '15px', color: '#253872' }}>Send Notification To? </Box>
                <FormControlLabel value="createdBy" control={<Radio size={'small'} sx={{
                    color: "#D9D9D9",
                    '&.Mui-checked': {
                        color: "#253872",
                    },
                }} />} label={user?.given_name + " " + user?.name + " " + user?.family_name} />

                <FormControlLabel value="contactGroup" control={<Radio size={'small'} sx={{
                    color: "#D9D9D9",
                    '&.Mui-checked': {
                        color: "#253872",
                    },
                }} />} label={selectedYOContactGroup.departmentName + ' Contact Group'} />

            </RadioGroup>}




            {projectType == "Existing" && selectedExistingProject != undefined && selectedExistingProject != 0 && <ProjectReportAttachments schoolId={schoolState.id} projectId={selectedExistingProject} />}

            <Box sx={{ paddingRight: '15px', paddingTop: '25px', paddingBottom: '8px' }}>Add Attachment: </Box>

            <UploadProjectFile uploadPath='' onSuccess={() => setSuccessfulUpload(true)} setUploadFunction={setSelectedFiles} />
            {/*{(roles?.includes('SuperAdmin') || roles?.includes('Admin')) && projectType == "New" && <RadioGroup*/}
            {/*    row*/}
            {/*    aria-labelledby="demo-row-radio-buttons-group-label"*/}
            {/*    name="row-radio-buttons-group"*/}
            {/*    sx={{ marginTop: '18px' }}*/}
            {/*    value={allSchools}*/}
            {/*    onChange={handleAllSchoolsChange}*/}
            {/*>*/}
            {/*    <Box sx={{ paddingTop: '8px', paddingRight: '15px' }}>Create project for all schools? </Box>  <FormControlLabel value="Yes" control={<Radio size={'small'} sx={{*/}
            {/*        color: "#D9D9D9",*/}
            {/*        '&.Mui-checked': {*/}
            {/*            color: "#253872",*/}
            {/*        },*/}
            {/*    }} />} label="Yes" />*/}
            {/*    <FormControlLabel value="No" control={<Radio size={'small'} sx={{*/}
            {/*        color: "#D9D9D9",*/}
            {/*        '&.Mui-checked': {*/}
            {/*            color: "#253872",*/}
            {/*        },*/}
            {/*    }} />} label="No" />*/}

            {/*</RadioGroup>}*/}

            {checkIsProjectValid() && (
                <div style={{ paddingTop: '10px' }}>
                    <Button onClick={handleUpload} variant="contained" disabled={loading || (success && uploadSuccess)} style={{ backgroundColor: '#253872' }}>
                        {projectType == "New"? "Create" : "Update"}
                        
                    </Button>
                </div>
            )}


            {success && uploadSuccess && <div style={{ color: '#72E889', marginTop: '8px' }}>
                Project or Report Successfully {projectType == "New" ? "Created" : "Updated"}!
            </div>}

            {error &&
                <div style={{ color: '#FB4A4A', marginTop: '8px' }}>
                    {error}
                </div>
            }
            {uploadError &&
                <div style={{ color: '#FB4A4A', marginTop: '8px' }}>
                    {uploadError}
                </div>
            }
        </div>

    );
};

export default ProjectReportSelector;

