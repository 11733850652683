import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToken } from '../../useToken';
import styled from "@emotion/styled";
import AnnouncementDownloadIcon from './AnnouncementDownloadIcon';
import AnnouncementPrintIcon from '../PrintIconButton';
import DocViewer, { DocViewerRenderers, IDocument, PDFRenderer } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import '../../styles.css';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';


const Wrapper = styled.div`
 width: 80%;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
margin-top:24px;
margin-bottom:50px;

`


const AnnouncementViewer = () => {
 //   const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const location = useLocation();
    const [state] = useState(location.state || {});
    const [announcementId, setAnnouncementId] = useState<number>(0);
    const announcementState = useAppSelector((state) => state.announcement);
    const schoolState = useAppSelector((state) => state.school);
    const [loading, setLoading] = useState(true);
    const [blobUrl, setBlobUrl] = useState<string>('');
    const [blob, setBlob] = useState<Blob>();
    const [docs, setDocs] = useState<IDocument[]>();
    

    const GetAnnouncement = async () => {
        try {
            const localToken = await getToken();
           // const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.get(`${API_BASE_URL}/api/Document/getannouncementDocument/${announcementState.announcementId }`, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });


            const blob = new Blob([response.data], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
             setBlob(blob);
             setBlobUrl(blobUrl);
           const docs = [
                { uri: blobUrl,fileType:'pdf',fileName:'Portal Announcement.pdf' }, // Remote file
       
            ];
            setDocs(docs);
          
          
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };
      

    useEffect(() => {
        if (blobUrl == '') {


            setAnnouncementId(state);
       //     console.log(announcementId);
            GetAnnouncement();
        }
        else {
            setLoading(false);
        }

    }, [loading]);

    return (
        <><div className="pageHeader">Announcement</div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '80%' }}> <AnnouncementDownloadIcon blob={blob} announcementId={announcementState.announcementId} />
                <AnnouncementPrintIcon blob={blob} fileName='Portal Announcement.pdf' filePath={'announcements'} studentId={0} schoolId={schoolState.school.id} folderCategory={'Announcement'} ></AnnouncementPrintIcon></div> 
            <Wrapper>
              
                {blobUrl && <DocViewer 
                    documents={docs ?? [
                        { uri: '' },
                    ]}
                    style={{ height: 800, overflow:'auto' }}
                    pluginRenderers={[PDFRenderer]} config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                        }, pdfVerticalScrollByDefault: true
                    }} ></DocViewer>
                                }
           

        </Wrapper></>
    );
};

export default AnnouncementViewer;