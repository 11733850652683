import React, { useState } from 'react';
import { ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import SchoolData from '../Processing/SchoolData';
import { CloseButton } from '../CloseButton';
import UploadMultipleFiles from '../UploadMultipleFiles';
import Loader from '../Loader';
import DownloadDocuments from './DownloadDocuments';

type Props = {
    fileTitle: string;
    fileId: number;
    fileType: string;
    schoolId: number | null;
    onClose: () => void;
}


const FileUploadModal = ({ onClose, fileTitle, fileId,fileType, schoolId }: Props) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [componentKey, setComponentKey] = useState(0);

    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );
    const handleReset = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            setSuccess(false);
            setComponentKey(prevKey => prevKey + 1);
        }, 4000);
    };
    const acceptedFileTypes = () => {
        // Define a base object with all possible MIME types
        const baseAcceptedTypes = {
            //'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            //'application/vnd.ms-excel': [],
            //'application/pdf': [],
            //'text/plain': [],
            //'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        };

        // Modify the array values based on the fileType
        switch (fileType) {
            case 'xlsx':
                return {
                    ...baseAcceptedTypes,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.ms-excel': ['.xls'],
                };
            case 'pdf':
                return {
                    ...baseAcceptedTypes,
                    'application/pdf': ['.pdf'],
                };
            case 'text':
                return {
                    ...baseAcceptedTypes,
                    'text/plain': ['.txt'],
                };
            case 'document':
                return {
                    ...baseAcceptedTypes,
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                };
            default:
                return baseAcceptedTypes; // Return the base object with empty arrays
        }
    };


    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{fileTitle} Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                {loading && <Loader />}
                <ModalBody>
                    <div style={{ marginTop:'24px' }}>
                      {/*  <SchoolData projectDescription={fileTitle} projectId={fileId} />*/}
                        {success ?
                            (<div style={{ color: '#5EB234' }}>File(s) Successfully Uploaded!</div>)
                            : <UploadMultipleFiles
                                uploadPath={`api/document/UploadAnnualSchoolFileDocument/${schoolId}/${fileId}`}
                                // uploadPath={`api/document/uploadSchoolNeedDocument/${requirementId}`}
                                loading={loading}
                                setLoading={setLoading}
                                onSuccess={() => { setSuccess(true), handleReset() }}
                                acceptedFileTypes={acceptedFileTypes() } />
                                   // {
                                    //'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                    //'application/vnd.ms-excel': ['.xls'],
                                    //'application/pdf': ['.pdf'],
                                    //'text/plain': ['.txt'],
                                    //'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], }
                              

                        } </div>
                    <DownloadDocuments key={componentKey} fileId={fileId} schoolId={schoolId??0} />

                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default FileUploadModal;
