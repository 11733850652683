import React, { useEffect, useState } from 'react';
import { FilterOption, ModalBody, ModalContent, ModalHeader, ModalOverlay } from './FoldersStyles';
import { CloseButton } from '../CloseButton';
import { Button } from '@mui/material';

type Props = {
    onClose: () => void;
    filterTypes: string[];
    setFilterFunction: any;
    folderType: string;
    //onFilterTypeChange: (newType: string[]) => void;
}

const FilterModal = ({ onClose, filterTypes, setFilterFunction, folderType }: Props) => {

    const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>(filterTypes);
    // setSelectedFilterTypes(filterTypes);
    const handleFilterChange = (filterType: string) => {



        setSelectedFilterTypes((prevFilterTypes) => {
            let updatedFilterTypes: string[];
            if (prevFilterTypes.includes(filterType)) {
                updatedFilterTypes = prevFilterTypes.filter((type) => type !== filterType);
            } else {
                updatedFilterTypes = [...prevFilterTypes, filterType];
            }

            // If "Alpha" is selected, remove  student id filter 
            if (filterType === "Alpha") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'StudentId' && type !== 'DateDesc' && type !== 'DateAsc');
            }
            // If "studentid " is selected, remove alpha filter 
            if (filterType === "StudentId") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'Alpha');
            }

            // If "DateAsc" is selected, remove  DateDesc filter
            if (filterType === "DateAsc") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'DateDesc' && type !== 'Alpha');
            }
            // If "DateDesc " is selected, remove DateAsc filter
            if (filterType === "DateDesc") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'DateAsc' && type !== 'Alpha');
            }

            if (filterType == "Reset") {
                updatedFilterTypes = ["Reset"];

            } else {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'Reset');
            }
            return updatedFilterTypes; // Return the updated filter types list
        });
    };

    const applyFilters = () => {
        setSelectedFilterTypes(selectedFilterTypes);
    };

    useEffect(() => {
        setFilterFunction(selectedFilterTypes);
    }, [selectedFilterTypes]);

    return (
        <ModalOverlay onClick={onClose}>

            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Filter</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody>
                    <div style={{ paddingLeft: '5px' }}>
                        <FilterOption style={selectedFilterTypes.includes("Alpha") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Alpha') }}>Alphabetical Order</FilterOption>
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("StudentId") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('StudentId') }}>Student ID</FilterOption>}

                        {folderType == "ISIRS" && <FilterOption style={selectedFilterTypes.includes("DateAsc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('DateAsc') }}>Date Ascending</FilterOption>}
                        {folderType == "ISIRS" && <FilterOption style={selectedFilterTypes.includes("DateDesc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('DateDesc') }}>Date Descending</FilterOption>}
                        {/*{folderCategory == "Academic" &&     <FilterOption style={selectedFilterTypes.includes("ActiveStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('ActiveStudents') }}>Current Students</FilterOption>}*/}
                        {/*{folderCategory == "Academic" &&   <FilterOption style={selectedFilterTypes.includes("InactiveStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('InactiveStudents') }}>Past Students</FilterOption>}*/}
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("Current") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Current') }}>Current Students</FilterOption>}
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("Graduated") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Graduated') }}>Graduated Students</FilterOption>}
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("Withdrawn") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Withdrawn') }}>Withdrawn Students</FilterOption>}
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("ReverseDegree") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('ReverseDegree') }}>Reverse Degree</FilterOption>}
                        {folderType == "Academic" && <FilterOption style={selectedFilterTypes.includes("AllStudents") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('AllStudents') }}>All Students</FilterOption>}
                    </div>
                    <div>
                        <Button onClick={async () => {
                            applyFilters();
                            onClose()
                        }}
                            variant="contained" style={{ backgroundColor: '#253872', marginTop: '45px', marginBottom: '45px' }}>Apply
                        </Button>
                        <Button onClick={() => {
                            handleFilterChange('Reset');
                            // applyFilters();
                            // onClose();
                        }}
                            variant="outlined" style={{ color: '#253872', borderColor: '#253872', marginLeft: '5px', marginTop: '45px', marginBottom: '45px' }}>Clear
                        </Button>
                    </div>
                </ModalBody>

            </ModalContent>
        </ModalOverlay>
    );
};

export default FilterModal;
