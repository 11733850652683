import React, { useEffect, useState } from 'react';
import { StudentFile, StudentFolder } from '../../globalTypes/objects';
import axios from 'axios';
import { InactiveTag, StudentId, StudentName } from './FoldersStyles';
import '../../styles.css';
import { Box, Checkbox, CircularProgress, IconButton} from '@mui/material';
import "@fontsource/open-sans";
import { useToken } from '../../useToken';
import saveAs from 'file-saver';
import { API_BASE_URL } from '../../urlConfig';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import JSZip from 'jszip';
import FilterModal from './FilterModal';
import PreviousIcon from '../PreviousIcon';
import NextIcon from '../NextIcon';
import FileViewerModal from './FileViewerModal';

type Props = {
    studentFolders: StudentFolder[];

}


const TabAcademicStudentFiles = ({ studentFolders }: Props) => {
    const getToken = useToken();
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [showCurrent, setShowCurrent] = useState(true);
    const [showWithdrawn, setShowWithdrawn] = useState(true);
    const [showGraduated, setShowGraduated] = useState(true);
    const [showReverseDegrees, setShowReverseDegrees] = useState(true);
    const [allChecked, setAllChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [seed, setSeed] = useState(1);
    const [yearIndex, setYearIndex] = useState<number>(0);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(false);
    const [yearsList, setYearsList] = useState<string[]>([]);
    const [years, setYears] = useState<string>('');
    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [currentStudentFolder, setCurrentStudentFolder] = useState<StudentFolder>();

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, studentId: number) => {
        const updatedFolders = [...studentFolders];
        updatedFolders.forEach((folder) => {
            if (folder.studentId === studentId) {
                folder.isChecked = event.target.checked;
                setSeed(Math.random());
            }
        });
        studentFolders = (updatedFolders);
        const isEveryBoxChecked = studentFolders.filter(x => x.years == years).every((folder) => folder.isChecked);
        setAllChecked(isEveryBoxChecked);

    };
    const handleAllCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
        const allFolders = [...studentFolders];
        allFolders.forEach((folder) => {
            if (folder.years === years) {
                folder.isChecked = event.target.checked;
            }
        });
        studentFolders = (allFolders);
    };

    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        if (filterTypes.includes("Alpha") || filterTypes.includes("StudentId"))//this is a sort
        {
            filterTypes.forEach((type) => {
                switch (type) {
                    case type = "Alpha":
                        studentFolders = (studentFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                        setSeed(Math.random());
                        break;
                    case type = "StudentId":
                        studentFolders = (studentFolders.sort((a, b) => a.externalStudentId - b.externalStudentId));
                        setSeed(Math.random());
                        break;

                }
            });
        }

        //this is for the filter
        if (filterTypes.includes("AllStudents") || filterTypes.includes("Graduated") || filterTypes.includes("Current") || filterTypes.includes("Withdrawn") || filterTypes.includes("ReverseDegree") || filterTypes.includes("Reset")) {
            let showGraduated = false;
            let showWithdrawn = false;
            let showCurrent = false;
            let showReverseDegrees = false;

            filterTypes.forEach((type) => {
                switch (type) {

                    case type = "AllStudents":
                        //  setShowActive(true);
                        // setShowInactive(true);
                        showGraduated = true;
                        showCurrent = true;
                        showWithdrawn = true;
                        showReverseDegrees = true;


                        setSeed(Math.random());
                        break;
                    case type = "Current":
                        showCurrent = true;
                        setSeed(Math.random());
                        break;
                    case type = "Graduated":
                        showGraduated = true;
                        setSeed(Math.random());
                        break;
                    case type = "Withdrawn":
                        showWithdrawn = true;
                        setSeed(Math.random());
                        break;
                    case type = "ReverseDegree":
                        showReverseDegrees = true;
                        setSeed(Math.random());
                        break;
                    case type = "Reset":
                        studentFolders = (studentFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                        //setShowInactive(false);
                        //setShowActive(true);

                        showGraduated = true;
                        showCurrent = true;
                        showWithdrawn = true;
                        showReverseDegrees = true;
                        setSeed(Math.random());
                        break;
                }
            });
            setShowGraduated(showGraduated);
            setShowWithdrawn(showWithdrawn);
            setShowReverseDegrees(showReverseDegrees);
            setShowCurrent(showCurrent);

        }
        setLoading(false);
    };

    const handleArrowClick = (clickType: string) => {
        const addNumber = clickType == "Next" ? -1 : +1;
        const nextYearIndex = yearIndex != null ? yearIndex + addNumber : 0;

        const maxEnd = yearsList.length - 1;
        const newIndex = nextYearIndex < 0 ? 0 : nextYearIndex > maxEnd ? maxEnd : nextYearIndex;
        setYearIndex(newIndex);

        setYears(yearsList[newIndex]);
        setIsBeginning(nextYearIndex == yearsList.length - 1 ? true : false);
        setIsEnd(newIndex == 0 ? true : false);
        const isEveryBoxChecked = studentFolders.filter(x => x.years == yearsList[newIndex]).every((folder) => folder.isChecked);
        setAllChecked(isEveryBoxChecked);
        setSeed(Math.random());
    };
    const showFolder = (folder: StudentFolder) => {
        let showFolder = true;
        if (folder.years === years) {
            if ((showGraduated && folder.folderCategory == "Graduated")) {
                showFolder = true;
            }
            else if ((showWithdrawn && folder.folderCategory == "Withdrawn")) {
                showFolder = true;
            }
            else if ((showReverseDegrees && folder.folderCategory == "Reverse Degree")) {
                showFolder = true;
            }
            else if ((showCurrent && folder.folderCategory == "Current")) {
                showFolder = true;
            }
            else {
                showFolder = false;
            }

        }

        else {
            return false;
        }
        return showFolder;

    };




    const handleSelectedFoldersDownload = async () => {
        if (studentFolders.filter(x => x.isChecked).length < 1) {
            alert('Please select a student to download.');
        } else {
            const unresolvedPromises = studentFolders.map(async (folder) => {
                if (folder.isChecked && folder.years == years) {
                    downloadStudentFolder(folder.studentFiles, folder.firstName + "_" + folder.lastName + "_" + folder.externalStudentId, folder.externalStudentId, folder.schoolId, folder.folderType);
                }
            });
            const results = await Promise.all(unresolvedPromises);
        }
    }

    const downloadStudentFolder = async (studentFiles: StudentFile[], studentName: string, studentId: number, schoolId: number, folderCategory: string) => {


        const zip = new JSZip();
        for (let i = 0; i < studentFiles.length; i++) {
            try {
                const localToken = await getToken();
                const filePath = studentFiles[i].filePath;
                const fileName = studentFiles[i].fileName;
                const newFilePath = filePath.replaceAll('/', '*');
                logDownloadStudentFolder(fileName, filePath, studentId, schoolId, folderCategory);
                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                zip.file(studentFiles[i].fileName, blob);

            } catch (e) {
                console.error('Download failed', e);
            }
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, studentName + ".zip");
            });
    };

    const logDownloadStudentFolder = async (fileName: string, filePath: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Logger/addDownloadStudentFileLog`, {
            FilePath: filePath, FileName: fileName,
            StudentId: studentId.toString(),
            SchoolId: schoolId.toString(),
            FolderCategory: folderCategory
        }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                setError('');
            })
            .catch(error => {
                console.log(error?.response?.data);
                setError("Error while logging download");
            });
    };

    useEffect(() => {
        if (studentFolders.length > 0) {
            setLoading(false);
            const yearListInit = [...new Set(studentFolders.map(item => item["years"]))].sort((a, b) => b.substring(0, 4).localeCompare(a.substring(0, 4)));
            setYearsList(yearListInit);
            setYears(yearListInit[0]);
            setIsBeginning(yearListInit.length < 2 ? true : false);
            setIsEnd(true);
            const isEveryBoxChecked = studentFolders.filter(x => x.years == yearListInit[0]).every((folder) => folder.isChecked);
            setAllChecked(isEveryBoxChecked);

        } else {
            setLoading(false);
        }

    }, []);


    return (
        <>
            {openFilterModal && <FilterModal folderType={"Academic"} onClose={() => { setLoading(true); handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} />}
            {openFileViewerModal && currentStudentFolder && <FileViewerModal onClose={() => { setOpenFileViewerModal(false) }} studentFolder={currentStudentFolder}  />}
            {error != '' && <div style={{ color: 'red', marginLeft: '20px' }}>{error}</div>}
            <>
                <Box sx={{
                    maxWidth: '1400px', minWidth: '720px', overflow: 'auto', borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: '#F4F5F7', marginTop: '20px', width: '100% '
                }}>

                    <Box
                        sx={{
                            marginLeft: '24px', display: 'flex', justifyContent: 'flex-start', width: '94%', paddingTop: '20px'
                        }}
                    >

                        <IconButton sx={{ borderRadius: '4px', padding: '0', height: '37px' }} onClick={() => { setOpenFilterModal(true) }}><img src={filterButtonIcon} alt="Filter Icon" /></IconButton>
                        <div style={{ display: 'flex', marginLeft: '24px', minWidth: '150px' }}>
                            <Checkbox
                                checked={allChecked}
                                onChange={handleAllCheckBoxChange}
                                sx={{
                                    marginTop: '0px',
                                    color: 'rgba(131, 151, 248, 0.50)',
                                    '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)' },
                                    height: '42px',
                                    width: '42px'
                                }}
                            />
                            <div style={{ marginTop: '9px', width: '100px' }}>Select All</div>
                        </div>


                        <div style={{ display: 'flex', width: '80%', paddingBottom: '5px',  justifyContent: 'flex-end' }}>
                            <IconButton onClick={() => (handleSelectedFoldersDownload())} style={{ width: '40px', height: '40px', marginTop: '5px' }}> <img
                                src={downloadIcon}
                                style={{ width: '40px', height: '40px' }}

                            /></IconButton>
                            <div style={{
                                display: 'flex',
                                marginLeft: '12px'
                            }} > <PreviousIcon disableArrow={isBeginning} onClick={() => handleArrowClick("Previous")} />
                                <div style={{ paddingTop: '12px', paddingLeft: '2px', paddingRight: '2px' }}> {years}</div>
                                <NextIcon showNext={false} onClick={() => handleArrowClick("Next")} disableArrow={isEnd} />
                            </div>
                        </div>   </Box>

                </Box>

                <Box key={seed}  >
                    <div> {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '500px' }} />}
                        {!loading && studentFolders && studentFolders.length == 0 && <div style={{ paddingLeft: '25px', paddingTop: '25px' }}>No Files Found!</div>}
                    </div>
                    {studentFolders && studentFolders.map((folder, index) => (

                        <Box key={index}
                            sx={showFolder(folder)
                                ? { display: 'flex', borderBottom: '1px solid #F4F5F7', } : { display: 'none' }}
                        >
                            <Box sx={{
                                paddingLeft: '26px',
                                width: '50px', paddingTop: '16px', paddingBottom: '16px'
                            }} >

                                <Checkbox id={"ckStudent" + folder.studentId} checked={folder.isChecked ?? false}
                                    name={'studentCheckbox'} onChange={(e) => { handleCheckBoxChange(e, folder.studentId) }}
                                    sx={{
                                        color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', }, height: '38px', width: '38px'
                                    }} /></Box>
                            <Box onClick={() => { setOpenFileViewerModal(true), setCurrentStudentFolder(folder) }}
                                sx={{
                                    // minWidth: '200px', width: '500px',
                                    width: { xs: '300px', sm: '300px', md: '400px', lg: '700px', xl: '1000px' },
                                    display: 'flex',
                                    paddingLeft: '0px'
                                    , paddingTop: '16px', paddingBottom: '16px', cursor: 'pointer',
                                    //  backgroundColor: clicked ? 'lightblue' : 'transparent', // Change background on click
                                    '&:hover': {
                                        color: '#7f96ff', // Change background on hover  backgroundColor: '#f6f8ff',
                                    },
                                    transition: 'background-color 0.3s ease', // Smooth transition for background change

                                }}> <StudentName>{folder.lastName + " " + folder.firstName}</StudentName><StudentId>#{folder.externalStudentId}</StudentId>
                                {!folder.isCurrent && <InactiveTag>{folder.folderCategory}</InactiveTag>}
                            </Box>


                        </Box>

                    ))}
                </Box>
            </>

        </>
    );
};

export default TabAcademicStudentFiles;

