import React, { useEffect, useState } from 'react';
import { LogFileDownload, StudentFile, StudentFolder } from '../../globalTypes/objects';
import axios from 'axios';
import { DownloadIcon, StudentName, UploadText, UploadTextNoDownload, WrapperNoDownload } from './FoldersStyles';
import '../../styles.css';
import { Box, Checkbox, CircularProgress, Icon, IconButton } from '@mui/material';
import "@fontsource/open-sans";
import { useToken } from '../../useToken';
import saveAs from 'file-saver';
import { API_BASE_URL } from '../../urlConfig';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import printJS from 'print-js';
import FilterModal from './FilterModal';
import NextIcon from '../NextIcon';
import PreviousIcon from '../PreviousIcon';
import FileViewerModal from './FileViewerModal';
import { useAppSelector } from '../../store';
import FileDownloadIcon from './FileDownloadIcon';
import FileUploadIcon from './FileUploadIcon';
import greyCheckIcon from '../../Images/greyCheckIcon.svg';
import greenCheckIcon from '../../Images/greenCheckIcon.svg';
import JSZip from 'jszip';
import DateIcon from './DateIcon';
import dayjs from 'dayjs';
import noDownloadIcon from '../../Images/GreyDownloadIcon.svg';


type Props = {
    folderCategory: string;

}


const TabMonitoringDocuments = ({ folderCategory }: Props) => {
    const getToken = useToken();
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [folders, setFolders] = useState<StudentFolder[]>([]);
    const [currentStudentFolder, setCurrentStudentFolder] = useState<StudentFolder>();
    const [error, setError] = useState('');
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [showInactive, setShowInactive] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [allChecked, setAllChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [seed, setSeed] = useState(1);
    const [yearIndex, setYearIndex] = useState<number>(0);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(false);
    const [yearsList, setYearsList] = useState<string[]>([]);
    const [years, setYears] = useState<string>('');
    const [studentFolders, setStudentFolders] = useState<StudentFolder[]>([]);


    const loadData = async () => {
        let data: StudentFolder[] = [];
        setLoading(true);
        try {
            const localToken = await getToken();
            // const folderCategory = folderCategory;// 'Monitoring Documents'
            await axios.get<StudentFolder[]>
                (`${API_BASE_URL}/api/Folders/getAllFolders/${selectedSchoolId}/${folderCategory}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    }).then((res) => {
                        data = res.data;
                        return res.data;

                    })
                .catch(error => {
                    console.log(error?.response?.data);

                });

            setLoading(false);

        } catch ({ message }) {
            setError('An unknown error occurred' || message);
            setLoading(false);
        }
        return data;
    };

    //const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, studentId: number) => {
    //    const updatedFolders = [...studentFolders];
    //    updatedFolders.forEach((folder) => {
    //        if (folder.externalStudentId === studentId) {
    //            folder.isChecked = event.target.checked;
    //            setSeed(Math.random());
    //        }
    //    });
    //    setStudentFolders(updatedFolders);
    //    const isEveryBoxChecked = studentFolders.filter(x => x.years == years).every((folder) => folder.isChecked);
    //    setAllChecked(isEveryBoxChecked);

    //};
    //const handleAllCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    setAllChecked(event.target.checked);
    //    const allFolders = [...studentFolders];
    //    allFolders.forEach((folder) => {
    //        if (folder.years == years) {
    //            folder.isChecked = event.target.checked;
    //        }
    //    });
    //    setStudentFolders(allFolders);
    //};

    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "Alpha":
                    (studentFolders.sort((a, b) => a.folderName.localeCompare(b.folderName)));
                    // setSeed(Math.random());
                    break;
                case type = "Reset":
                    (studentFolders.sort((a, b) => a.folderName.localeCompare(b.folderName)));
                    //setShowInactive(false);
                    //setShowActive(true);
                    //  setSeed(Math.random());
                    break;
            }
        });
        //  setLoading(false);
    };

    const handleApprovedFoldersDownload = async () => {
        if (studentFolders.filter(x => x.approved).length < 1) {
            alert('No approved files found.');
        }
        else {
            const approvedFiles: StudentFile[] = [];
            const unresolvedPromises = studentFolders.map(async (folder) => {
                if (folder.approved) {
                    folder.studentFiles.filter(x => x.approved).map(x => {
                        approvedFiles.push(x);
                    })

                }
            });
            downloadFile(approvedFiles, selectedSchoolId, folderCategory);
            const results = await Promise.all(unresolvedPromises);
        }
    }

    const handleViewCurrentFolders = async () => {

        const approvedFiles: StudentFile[] = [];
        const unresolvedPromises = studentFolders.map(async (folder) => {

            folder.studentFiles.filter(x => !x.isForm).map(x => {
                approvedFiles.push(x);
            })

        });
        const results = await Promise.all(unresolvedPromises);
    }

    const downloadFile = async (files: StudentFile[], schoolId: number, folderCategory: string) => {


        const zip = new JSZip();
        for (let i = 0; i < files.length; i++) {
            try {
                const localToken = await getToken();
                const filePath = files[i].filePath;
                const fileName = files[i].fileName;
                const saveAsFileName = files[i].saveAsFileName;
                //   const file = folder.studentFiles.filter(s => s.isForm).;
                const newFilePath = filePath.replaceAll('/', '*');
                logDownloadStudentFolder(fileName, filePath, i, schoolId, folderCategory);
                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                zip.file(saveAsFileName, blob);
                // saveAs(blob, saveAsFileName);
                setError('');

            } catch (e) {
                console.error('Download failed', e);
                setError("Error while downloading file.")
            }
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, folderCategory + ".zip");
            });
    };

    const downloadForm = async (files: StudentFile[], schoolId: number, folderCategory: string) => {


        // const zip = new JSZip();
        for (let i = 0; i < files.length; i++) {
            try {
                const localToken = await getToken();
                const filePath = files[i].filePath;
                const fileName = files[i].fileName;
                const saveAsFileName = files[i].saveAsFileName;
                //   const file = folder.studentFiles.filter(s => s.isForm).;
                const newFilePath = filePath.replaceAll('/', '*');
                logDownloadStudentFolder(fileName, filePath, i, schoolId, folderCategory);
                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                //  zip.file(saveAsFileName, blob);
                saveAs(blob, fileName);
                setError('');

            } catch (e) {
                console.error('Download failed', e);
                setError("Error while downloading file.")
            }
        }

    };




    const logDownloadStudentFolder = async (fileName: string, filePath: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Logger/addDownloadStudentFileLog`, {
            FilePath: filePath, FileName: fileName,
            StudentId: studentId.toString(),
            SchoolId: schoolId.toString(),
            FolderCategory: folderCategory
        }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                setError('');
            })
            .catch(error => {
                console.log(error?.response?.data);
                setError("Error while logging download");
            });
    };



    const formatDate = (dateString: string) => {
        const date = dayjs(dateString);
        const formatedDate = date.format("MMM DD");
        return formatedDate;

    };


    useEffect(() => {
        loadData().then((data) => {
            setStudentFolders(data);
            if (data.length > 0 && isInitial) {
                setLoading(false);
                //  const yearListInit = [...new Set(studentFolders.map(item => item["years"]))].reverse();
                //  setYearsList(yearListInit);
                // setYears(yearListInit[0]);
                // setIsBeginning(yearListInit.length < 2 ? true : false);
                // setIsEnd(true);
                // setIsInitial(false);
                // const isEveryBoxChecked = studentFolders.filter(x => x.years == yearListInit[0]).every((folder) => folder.isChecked);
                // setAllChecked(isEveryBoxChecked);
            } else {
                setLoading(false);
            }
        });

    }, [seed, schoolState.id]);




    //const updateFileApproval = (id: number, approved: boolean) => {

    //    setStudentFolders((prevFolder: StudentFolder[]) => {
    //        const updatedStudentFiles = prevFolder.map(folder =>
    //            folder.studentFiles.map(file =>
    //                file.id  != null && file.id === id ? { ...file, approved } : file)
    //        );
    //        return { ...prevFolder, studentFiles: updatedStudentFiles };
    //    });
    //};


    return (
        <>
            {openFilterModal && <FilterModal folderType={"Annual School Files"} onClose={() => { setLoading(true); handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} />}
            {openFileViewerModal && currentStudentFolder && <FileViewerModal onClose={() => { setOpenFileViewerModal(false), setSeed(Math.random) }} studentFolder={currentStudentFolder} />}
            {error != '' && <div style={{ color: 'red', marginLeft: '20px' }}>{error}</div>}
            <>

                <Box sx={{
                    maxWidth: '1400px', borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: '#F4F5F7', marginTop: '20px', width: '100% '
                }}>

                    <div key={seed} style={{ marginLeft: '24px', display: 'flex', justifyContent: 'flex-start', width: '94%', paddingTop: '20px' }}>
                        <IconButton sx={{ borderRadius: '4px', padding: '0', height: '37px' }} onClick={() => { setOpenFilterModal(true) }}>
                            <img src={filterButtonIcon} alt="Filter Icon" /></IconButton>

                        {/*<div style={{*/}
                        {/*    display: 'flex', marginLeft: '24px', minWidth: '150px'*/}
                        {/*}} >  <Checkbox checked={allChecked} onChange={handleAllCheckBoxChange} sx={{*/}
                        {/*    marginTop: '0px',*/}
                        {/*    color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', },*/}
                        {/*    height: '42px', width: '42px'*/}
                        {/*}} />*/}
                        {/*    <div style={{ marginTop: '9px', width: '100px' }}>Select All</div></div>*/}


                        <div style={{ display: 'flex', width: '80%', paddingBottom: '5px', marginBottom: '15px', justifyContent: 'flex-end' }}>
                            <div>  <IconButton onClick={() => (handleApprovedFoldersDownload())} style={{ width: '40px', height: '40px', marginLeft: '30px' }}> <img
                                src={downloadIcon}
                                style={{ width: '40px', height: '40px' }}
                            /></IconButton>
                                <UploadText>Completed Package</UploadText> </div>

                            {/*<div style={{ display: 'flex', marginLeft: '12px' }}>*/}
                            {/*    <PreviousIcon disableArrow={isBeginning} onClick={() => handleArrowClick("Previous")} />*/}
                            {/*    <div style={{ paddingTop: '12px', paddingLeft: '2px', paddingRight: '2px' }}> {years}</div>*/}
                            {/*    <NextIcon showNext={false} onClick={() => handleArrowClick("Next")} disableArrow={isEnd} />*/}

                            {/*</div>*/}
                        </div>
                    </div>

                </Box>

                <Box key={seed} >
                    {/*<div>    {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '500px' }} />}*/}
                    {/*    </div>*/}
                    {studentFolders && studentFolders.length > 0 && <div> {studentFolders.map((folder, index) => (

                        <Box key={index}
                            sx={{ display: 'flex', borderBottom: '1px solid #F4F5F7', }}
                        >

                            <Box sx={{
                                paddingLeft: '36px',
                                width: '30px', paddingTop: '26px'//, paddingBottom: '16px'
                            }} >
                                {/*<Checkbox id={"ckStudent" + folder.externalStudentId} checked={folder.isChecked ?? false}*/}
                                {/*    name={'studentCheckbox'} onChange={(e) => { handleCheckBoxChange(e, folder.externalStudentId) }}*/}
                                {/*    sx={{*/}
                                {/*        color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', }, height: '38px', width: '38px'*/}
                                {/*    }} />*/}


                                <img title="Approved"
                                    height='15px' width='15px' src={folder.approved ? greenCheckIcon : greyCheckIcon} />
                            </Box>

                            <Box sx={

                                {
                                    width: { xs: '300px', sm: '300px', md: '400px', lg: '600px', xl: '820px' },
                                    display: 'flex',
                                    paddingLeft: '10px',
                                    paddingTop: '16px',
                                    paddingBottom: '16px',
                                    


                                }}>  {folder.studentFiles.filter(x => !x.isForm).length > 0 ?
                                    <Box onClick={() => { setOpenFileViewerModal(true), setCurrentStudentFolder(folder) }}
                                        sx={

                                            { width:'70%',
                                                justifyContent: 'flex-start', 
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: '#7f96ff', // Change background on hover  backgroundColor: '#f6f8ff',
                                                },
                                                transition: 'background-color 0.3s ease', // Smooth transition for background change


                                            }}>
                                        <StudentName> {folder.folderName} </StudentName>

                                    </Box> :
                                    <Box sx={{
                                        width: '70%',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                        <StudentName style={{
                                                fontWeight: '400'
                                            }}> {folder.folderName}</StudentName>

                                    </Box>
                                }
                                <Box sx={{ justifyContent: 'flex-end', width: '30%', paddingTop: '8px', fontSize: '13px', color: '#B1B1B1', fontStyle: 'italic' }} >Prepared by {folder.doneBy} </Box></Box>

                            <Box sx={{
                                paddingLeft: '0px',
                                minWidth: '20px', width: '10%', paddingTop: '16px', paddingBottom: '16px'
                            }}>
                                {folder.folderDate != '' &&
                                    <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(folder.folderDate)} />
                                }</Box>
                            {/*<div style={{  width: '30px', marginTop: '10px' }}>*/}
                            {/*    {folder.studentFiles.filter(s => s.isForm).length > 0 ?*/}


                            {/*        <div>*/}
                            {/*            <IconButton onClick={() => (downloadForm(folder.studentFiles.filter(s => s.isForm), folder.schoolId, folder.folderType))}*/}
                            {/*            style={{ width: '30px', height: '30px', marginLeft: '8px' }}> <img*/}
                            {/*                src={downloadIcon}*/}
                            {/*                style={{ width: '30px', height: '30px' }}*/}
                            {/*            /></IconButton>*/}
                            {/*            <UploadText>Forms</UploadText> </div> :*/}
                            {/*             <div>                       <div style={{ width: '30px', height: '30px', marginLeft: '8px' }}> <img*/}
                            {/*                src={noDownloadIcon}*/}
                            {/*                style={{ width: '30px', height: '30px' }}*/}
                            {/*            /></div>*/}
                            {/*            <UploadTextNoDownload>Forms</UploadTextNoDownload> </div>*/}


                            {/*    }*/}





                            {/*</div>*/}
                            <FileUploadIcon fileTitle={folder.folderName} fileId={folder.annualSchoolFileId} schoolId={selectedSchoolId} fileType={folder.reportFileExtension} onClose={() => { setSeed(Math.random) }}
                            />





                        </Box>


                    ))}</div>}
                </Box>
            </>

        </>
    );
};

export default TabMonitoringDocuments;

