import * as React from "react";
import styled from "@emotion/styled";

type Props = {
    dateText: string;   
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color:  rgba(37, 56, 114, 1);
  font-weight: 500;
  text-align: center;
  justify-content: center;

  border-radius: 30px;
  
    width: 65px;
  min-width: 65px;
  max-width: 65px;
`;

const DateText = styled.div`
  font-family: Open Sans, sans-serif;
  border-radius: 30px;
  background-color:rgba(131, 151, 248, 0.15);
  justify-content: center;
  padding:5px;
  font-weight:500;
    font-size: 13px;    
  height: 18px;
`;

const DateIcon = ({dateText}: Props) => {
    return (
        <Wrapper key={self.crypto.randomUUID()}>
            <DateText key={self.crypto.randomUUID()}>{dateText}</DateText>
        </Wrapper>
    );
}


export default DateIcon;
