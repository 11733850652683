import React, { useEffect, useState } from 'react';
import { FilterOption, ModalBody, ModalContent, ModalHeader, ModalOverlay } from './FeedbackStyles';
import { CloseButton } from '../CloseButton';
import { Button } from '@mui/material';

type Props = {
    onClose: () => void;
    filterTypes: string[];
    setFilterFunction: any;
   // folderCategory: string;
    //onFilterTypeChange: (newType: string[]) => void;
}

const FilterModal = ({ onClose, filterTypes, setFilterFunction }: Props) => {

    const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>([]);
   // setSelectedFilterTypes(filterTypes);
    const handleFilterChange = (filterType: string) => {
      
        if (selectedFilterTypes.includes(filterType)) {
            setSelectedFilterTypes(selectedFilterTypes.filter((type) => type !== filterType));
        }

        else {
            setSelectedFilterTypes([...selectedFilterTypes, filterType]);
        }
        if (filterType == "Clear") {
            setSelectedFilterTypes(["Clear"]);

        }
        
    };
    const applyFilters = () => {
            setSelectedFilterTypes(selectedFilterTypes);
              };

    useEffect(() => {
        setFilterFunction(selectedFilterTypes);
    }, [selectedFilterTypes]);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Filter</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody>
{/*                    <FilterOption style={selectedFilterTypes.includes("Alpha") ? { border: '2px #253872 solid', boxShadow:'1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Alpha') }}>Alphabetical Order</FilterOption>*/}
                    <FilterOption style={selectedFilterTypes.includes("Resolved") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Resolved') }}>Resolved</FilterOption>            
                    <FilterOption style={selectedFilterTypes.includes("Unresolved") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Unresolved') }}>Unresolved</FilterOption>            

                    { <FilterOption style={selectedFilterTypes.includes("DateAsc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('DateAsc') }}>Date Ascending</FilterOption>}
                    {<FilterOption style={selectedFilterTypes.includes("DateDesc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('DateDesc') }}>Date Descending</FilterOption>}
{/*                    {<FilterOption style={selectedFilterTypes.includes("Deleted") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Deleted') }}>Deleted</FilterOption>}*/}
                    {<FilterOption style={selectedFilterTypes.includes("UserAsc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('UserAsc') }}>User Ascending</FilterOption>}
                    {<FilterOption style={selectedFilterTypes.includes("UserDesc") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('UserDesc') }}>User Descending</FilterOption>}
                    {<FilterOption style={selectedFilterTypes.includes("HES") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('HES') }}>HES Feedback</FilterOption>}
                    {<FilterOption style={selectedFilterTypes.includes("School") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('School') }}>School Feedback</FilterOption>}
                    {<FilterOption style={selectedFilterTypes.includes("All") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('All') }}>View All</FilterOption>}

                    <div>
                        <Button onClick={async () => {
                            applyFilters();
                            onClose()
                            }}
                            variant="contained" style={{ backgroundColor: '#253872', marginTop: '45px' }}>Apply
                        </Button>
                        <Button onClick={ () => {
                            handleFilterChange('Clear');
                           // applyFilters();
                           // onClose();
                        }}
                            variant="outlined" style={{ color: '#253872', borderColor:'#253872',marginLeft: '5px', marginTop:'45px' }}>Clear
                        </Button>
                    </div>
                </ModalBody>
               
            </ModalContent>
        </ModalOverlay>
    );
};

export default FilterModal;
