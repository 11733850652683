import React, { useState } from 'react';
import styled from '@emotion/styled';
import previousIcon from '../Images/PreviousIcon.svg';
import greyPreviousIcon from '../Images/greyPreviousIcon.svg';
import { IconButton } from '@mui/material';

type Props = {
    disableArrow: boolean;
    onClick: () => void;
}


const Icon = styled.img`
width: 28px;
overflow: hidden;
height: 28px;


`;

const PreviousIcon = ({ disableArrow, onClick }: Props) => {

    return (
        <IconButton onClick={onClick} disabled={disableArrow?true:false}>

            <Icon src={disableArrow ? greyPreviousIcon :previousIcon} alt="Next"  />

        </IconButton>
    );
};
export default PreviousIcon;
