import React from 'react';
import { FileModalBody, FileModalContent, FileModalHeader, ModalOverlay } from './FoldersStyles';
import { CloseButton } from '../CloseButton';
import FileViewer from './FileViewer';
import { StudentFolder } from '../../globalTypes/objects';

type Props = {
    onClose: () => void;
    studentFolder: StudentFolder;
    // updateFileApproval: (id: number, approved: boolean) => void;
}

const FileViewerModal = ({ onClose, studentFolder }: Props) => {


    return (
        <ModalOverlay onClick={onClose}>

            <FileModalContent onClick={(e) => e.stopPropagation()}>
                <FileModalHeader>
                    {(studentFolder.folderType == "Academic Student Files" || studentFolder.folderType == "ISIRS") ? <div>{studentFolder.firstName + " " + studentFolder.lastName}</div> :
                        <div>{studentFolder.folderName}</div>}
                </FileModalHeader>

                <CloseButton onClick={onClose} />
                <FileModalBody>
                    <FileViewer studentFolder={studentFolder} />
                </FileModalBody>

            </FileModalContent>
        </ModalOverlay>
    );
};

export default FileViewerModal;
