import axios from 'axios';
import React, { useState, useEffect, act } from 'react';
import { useLocation } from 'react-router-dom';
import { useToken } from '../../useToken';
import styled from "@emotion/styled";
import PrintIcon from '../PrintIconButton';
import DocViewer, { DocViewerRenderers, IDocument, PDFRenderer } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import '../../styles.css';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';
import { PDFDoc, StudentFile, StudentFolder } from '../../globalTypes/objects';
import FileDownloadIcon from './FileDownloadIcon';
import { Checkbox, IconButton } from '@mui/material';
import nextIcon from '../../Images/NextIcon.svg';
import greyNextIcon from '../../Images/greyNextIcon.svg';
import previousIcon from '../../Images/PreviousIcon.svg';
import greyPreviousIcon from '../../Images/greyPreviousIcon.svg';
import { folder } from 'jszip';
import PdfViewer from '../Announcements/PdfViewer';
import { PDFDocument } from 'pdf-lib';

const Wrapper = styled.div`
margin:24px;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);


`
type Props = {
    studentFolder: StudentFolder;
   /* updateFileApproval: (id: number, approved: boolean) => void;*/

}


const FileViewer = ({ studentFolder }: Props) => {
    // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const location = useLocation();
    const [state] = useState(location.state || {});
    const [announcementId, setAnnouncementId] = useState<number>(0);
    const announcementState = useAppSelector((state) => state.announcement);
    const [loading, setLoading] = useState(true);
    const [blobUrl, setBlobUrl] = useState<string>('');
    const [blob, setBlob] = useState<Blob>();
    const [docs, setDocs] = useState<PDFDoc[]>([]);
    const [activeDocument, setActiveDocument] = useState<PDFDoc | null>(null);
    const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
    const [currentFolder, setCurrentFolder] = useState<StudentFolder>(studentFolder);
    const [seed, setSeed] = useState(0);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState<string[] | string>();



    const downloadStudentFolder = async (studentFiles: StudentFile[]) => {
        const studentFilesForView = studentFiles.filter(x => !x.isForm);
        const docs: React.SetStateAction<PDFDoc[] | undefined> = [];
        for (let i = 0; i < studentFilesForView.length; i++) {
            try {
                setError('');
                const localToken = await getToken();
                const filePath = studentFilesForView[i].filePath;
                const fileName = studentFilesForView[i].fileName;
                const newFilePath = filePath.replaceAll('/', '*');
                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`
                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
                setBlob(blob);
                setBlobUrl(blobUrl);
                docs.push(
                    { uri: blobUrl, fileType: 'pdf', fileName: studentFilesForView[i].fileName, blob: blob, filePath: studentFilesForView[i].filePath, approved: studentFilesForView[i].approved, id: studentFilesForView[i].id }, // Remote file
                );


            } catch (e) {
                console.error('Error rendering pdf', e);
                setError('Error rendering pdf');
            }
        }
        if (docs.length > 0) {


            setDocs(docs);

            const blob = docs[0].blob ?? new Blob();
            //checkIfPdfIsPasswordProtected(blob)
            //    .then(async isProtected => {
            //        console.log(`The PDF file is ${isProtected ? 'password protected' : 'not password protected'}`);
            //        if (isProtected) {
            //            const password = window.prompt("Please enter your password:");
            //            const pdfBytes =  await blob.arrayBuffer();
            //          //  const pdfDoc =  PDFDocument.load(pdfBytes,  password );

            //        }
            //    })
            //    .catch(error => console.error('Error checking PDF:', error));

            setActiveDocument(docs[0]);
            logViewFile(docs[0].fileName ?? '', docs[0].filePath ?? '', currentFolder.externalStudentId, currentFolder.schoolId, currentFolder.folderType);
            setSeed(Math.random);
        }
    };
    useEffect(() => {
        const originalPrompt = window.prompt;

        // Wrap the original window.prompt
        window.prompt = (message, defaultValue) => {
            // Show the prompt and capture the user input
            const result = originalPrompt(message, defaultValue);

            // Check if the result is null, indicating "Cancel" was clicked
            if (result === null) {
            //    console.log('User clicked Cancel');

                // Handle cancel action by resetting the active document state
                const defaultPDFDoc = {
                    uri: '', // Empty URI as a placeholder
                    fileName: docs[activeDocumentIndex].fileName, // Retain file name
                    approved: false, // PDF is not approved when canceled
                    fileType: 'application/pdf', // Default PDF file type
                    fileData: '', // Empty file data since the PDF can't be rendered
                    blob: new Blob([], { type: 'application/pdf' }), // Empty Blob as placeholder
                };

                setActiveDocument(defaultPDFDoc); // Update the active document
                setSeed(Math.random()); // Random seed to trigger changes
                               
            }

            return result; // Return whatever the prompt result was (either user input or null)
        };

        // Cleanup on component unmount
        return () => {
            window.prompt = originalPrompt; // Restore the original prompt behavior
        };

    }, []);
     

    const logViewFile = async (fileName: string, filePath: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Logger/addViewFileLog`, {
            FilePath: filePath,
            FileName: fileName,
            StudentId: studentId.toString(),
            SchoolId: schoolId.toString(),
            FolderCategory: folderCategory
        }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                setError('');
            })
            .catch(error => {
                console.log(error?.response?.data);
                //  alert("Error while logging print");
            });
    };
    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);
    };
    useEffect(() => {
        setUserRoles();
        if (docs.length < 1) {
            downloadStudentFolder(currentFolder.studentFiles);
        }
        else {
            setLoading(false);
        }

    }, []);

    // Handle switching to the next document
    const goToNextDocument = () => {
        if (activeDocumentIndex < docs.length - 1) {
            setActiveDocumentIndex(activeDocumentIndex + 1);
            setActiveDocument(docs[activeDocumentIndex + 1]);
            logViewFile(docs[activeDocumentIndex + 1].fileName ?? '', docs[activeDocumentIndex + 1].filePath ?? '', currentFolder.externalStudentId, currentFolder.schoolId, currentFolder.folderType);
        }
        setSeed(Math.random)
    };

    // Handle switching to the previous document
    const goToPreviousDocument = () => {
        if (activeDocumentIndex > 0) {
            setActiveDocumentIndex(activeDocumentIndex - 1);
            setActiveDocument(docs[activeDocumentIndex - 1])
            logViewFile(docs[activeDocumentIndex - 1].fileName ?? '', docs[activeDocumentIndex - 1].filePath ?? '', currentFolder.externalStudentId, currentFolder.schoolId, currentFolder.folderType);

        }
        setSeed(Math.random)
    };
    const handleDocumentChange = (document: React.SetStateAction<PDFDoc | null>) => {
        setActiveDocument(document);
    };

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // const updatedFolder = [...studentFolder];
        let fileAlreadyApproved = false;
        currentFolder.studentFiles.forEach((file) => {
            if (file.approved && event.target.checked) {
                fileAlreadyApproved = true;  
            }
        });
        if (fileAlreadyApproved) {
            alert("A file was already approved for this report.");
        }
        else {
            if (activeDocument) {
                setActiveDocument({ ...activeDocument, approved: event.target.checked });

                const thisStudentFolder = currentFolder;
                const thisFile = thisStudentFolder.studentFiles.find(x => x.id == activeDocument.id);
                if (thisFile) thisFile.approved = event.target.checked;
                const updatedDocs = docs;
                const thisDoc = docs.find(x => x.id == activeDocument.id);
                if (thisDoc)
                {
                    thisDoc.approved = event.target.checked;
                    setActiveDocument(thisDoc);
            }
              //  studentFolder = thisStudentFolder;
                setCurrentFolder(thisStudentFolder);
                setDocs(updatedDocs);
               
                //updateFileApproval(activeDocument.id??999999, event.target.checked);
                UpdateSubmittedFileApproved(event.target.checked);
            }
        }

    };
    const UpdateSubmittedFileApproved = async (checked: boolean) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/document/updateSubmittedAnnualSchoolFileApproved`, { id: activeDocument?.id, approved: checked }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                setLoading(false);
                setError('');
            })
            .catch(error => {
                setError(error?.response?.data);
                setLoading(false);

            })
    };

return (
    <div>

        <div style={{ display: 'flex' }}>
           <div style={{ display: 'flex', width: '20%', paddingLeft: '25px', paddingBottom: '5px', paddingTop:'15px', justifyContent: 'start' }}>


                {(roles?.includes("SuperAdmin") || roles?.includes('HESManager')) && (currentFolder.folderType == "Monitoring Documents" || currentFolder.folderType == "Annual Report") &&

                    <> <Checkbox id={"ckApprove"} checked={activeDocument?.approved || false} 
                name={'ckApprove'} onChange={(e) => { handleCheckBoxChange(e) }}
                sx={{
                    color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', }, height: '38px', width: '38px'
                    }} />
                        <span style={{ marginTop: '8px' }} >Approve</span></>}
                {(!roles?.includes("SuperAdmin") && !roles?.includes('HESManager')) &&
                    (currentFolder.folderType == "Monitoring Documents" || currentFolder.folderType == "Annual Report") &&
                    <span style={{ marginTop: '8px', color: 'rgba(131, 151, 248)' }} >Approved</span>
                }

        </div>
        <div style={{ display: 'flex', width: '70%', paddingBottom: '5px', justifyContent: 'flex-end' }}>
            <FileDownloadIcon blob={activeDocument?.blob} filePath={activeDocument?.filePath ?? ''} schoolId={currentFolder.schoolId} studentId={currentFolder.externalStudentId} fileName={activeDocument?.fileName ?? ''} folderCategory={currentFolder.folderType} />
                <PrintIcon blob={activeDocument?.blob} filePath={activeDocument?.filePath ?? ''} schoolId={currentFolder.schoolId} studentId={currentFolder.externalStudentId} fileName={activeDocument?.fileName ?? ''} folderCategory={currentFolder.folderType}  ></PrintIcon>
            </div>
        </div>

        {blobUrl && docs?.length > 0 && <>
            {/* Navigation Buttons */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                    onClick={goToPreviousDocument}
                    disabled={activeDocumentIndex === 0}  // Disable if already at the first document
                >
                    <img src={activeDocumentIndex === 0 ? greyPreviousIcon : previousIcon} />
                </IconButton>
                {activeDocument?.fileName && < span style={{ margin: '10px 20px' }} key={activeDocument?.fileName}>
                    {activeDocument?.fileName}
                </span>}
                <IconButton
                    onClick={goToNextDocument}
                    disabled={activeDocumentIndex === docs.length - 1}  // Disable if already at the last document
                >
                    <img src={activeDocumentIndex === docs.length - 1 ? greyNextIcon : nextIcon} />
                </IconButton>
            </div>
            <Wrapper>
                {activeDocument && <DocViewer key={seed}
                    documents={docs??[
                        { uri: '' },
                    ]}
                    style={{ width: '100%', height: '600px', overflow: 'auto' }}
                    activeDocument={activeDocument ?? undefined}
                    onDocumentChange={handleDocumentChange}
                    //   pluginRenderers={[PDFRenderer]}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                        }, pdfVerticalScrollByDefault: true
                    }} ></DocViewer>
                }

                {/*    <Button onClick={() => {*/}
                {/*        handleDocumentNavigation()*/}
                {/*    setActiveDocument(docs[1])*/}
                {/*    setSeed(Math.random)*/}
                {/*}} >Next</Button>*/}
            </Wrapper> </>}

        {error != '' && <div style={{ color: 'red', marginLeft: '20px' }}>{error}</div>}
    </div>
);
};

export default FileViewer;