import React, { useContext, useEffect, useState } from 'react';
import { Need, Student, StudentRequirement } from '../../globalTypes/objects';
import axios from 'axios';
import { Tab, Tabs, TabList } from 'react-tabs';
import StudentUploadModal from './StudentUploadModal';
import NoteModal from './NoteModal';
import { useAuth0 } from "@auth0/auth0-react";
import {
    NoteButton,
    ProcessingButton,
    ProcessingButtonWrapper,
    ProcessingNeed,
    Uploaded,
    UploadedNeed,
    Wrapper
} from './ProcessingStyles';
import DownloadFormsModal from './DownloadFormsModal';
import StudentUploadMultipleModal from './StudentUploadMultipleModal';
import SelectedSchoolContext from '../SelectedSchoolContext';
import SchoolSelector from "../SchoolSelector";
import Loader from "../Loader";
import { School } from "../../types";
import DownloadForms from './DownloadForms';
import { useToken } from '../../useToken';
import NeedUploadIcon from './NeedUploadIcon';
import NeedCommentIcon from './NeedCommentIcon';
import '../../styles.css';
import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import { styled } from '@mui/material/styles';
import { TbRadiusBottomLeft } from 'react-icons/tb';
import { red } from '@mui/material/colors';
/*import { Button } from '../Button';*/
import Button from '@mui/material/Button';
import NeedItem from './NeedItem';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';
import NeedResponseModal from './NeedResponseModal';
import { SortButton } from '../Projects/ProjectsStyles';
import FilterModal from './FilterModal';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: '0px solid',
    color: theme.palette.text.secondary,
}));




const ProcessingNew = () => {
    const getToken = useToken();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState('');

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;



    const processingOptions = [
        { value: 0, label: "FA" },
        { value: 1, label: "Academic" },
        { value: 2, label: "Tuition" },
        { value: 3, label: "All Processing" }
    ]
    const [requirements, setRequirements] = useState<StudentRequirement[]>([]);
    const [unfilteredRequirements, setUnfilteredRequirements] = useState<StudentRequirement[]>([]);
    const [error, setError] = useState('');
    const [processingType, setProcessingType] = useState<any>(processingOptions.find(x => x.value == 0));
    const [selectedStudent, setSelectedStudent] = useState<Student>();
    const [openUploadModal, setOpenUploadModal] = useState<Need | null>(null);
    const [openUploadMultipleModal, setOpenUploadMultipleModal] = useState<Need[]>([]);
    const [openFormsModal, setOpenFormsModal] = useState(false);
    //const [openDownloadModal, setOpenDownloadModal] = useState<StudentRequirement | null>(null);
    const [openNoteModal, setOpenNoteModal] = useState<Need[]>([]);//this is really the need
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openNeedResponseModal, setOpenNeedResponseModal] = useState(false);
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [dateFilter, setDateFilter] = useState<string>("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, [selectedSchoolId]);


    const loadData = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get<StudentRequirement[]>
                (`${API_BASE_URL}/api/Processing/getStudentRequirements/${selectedSchoolId}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    });
            if (processingType?.label) {
                if (processingType?.label === 'All Processing') {
                    setRequirements(data);
                    setUnfilteredRequirements(data);
                } else {
                    const filteredData = data.map((d) => {
                        const matchingNeeds = d.needs.filter(n => n.type === processingType?.label);
                        return {
                            externalStudentId: d.externalStudentId,
                            studentId: d.studentId,
                            studentName: d.studentName,
                            needs: matchingNeeds
                        };


                    });

                    setRequirements(filteredData.filter(data => data.needs.length > 0))
                    setUnfilteredRequirements(filteredData.filter(data => data.needs.length > 0))
                }
            } else {
                setRequirements(data);
                setUnfilteredRequirements(data);
            }
        } catch ({ message }) {
            setError('An unknown error occurred' || message);
        }
    };

    const onUploadModalClose = async () => {
        setOpenUploadModal(null);
        setSelectedStudent(undefined);
        await loadData();
    }

    const onUploadMultipleModalClose = async () => {
        setOpenUploadMultipleModal([]);
        setSelectedStudent(undefined);
        await loadData();
    }


    const onNoteModalClose = async () => {
        setOpenNoteModal([]);
        setSelectedStudent(undefined);
        await loadData();
    }

    const tabStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        listStyleType: 'none',
        borderBottom: ' 0px solid #fff',
        backgroundColor: 'transparent', // make sure there's no background
        outline: 'none', // remove default outline
    };

    const activeTabStyle = {
        ...tabStyle,
        borderBottom: '3px solid',
        borderRadius: '2px',


    };

    useEffect(() => {
        loadData().then(r => console.log('r', r));
        //  console.log('error', error); //this needs to be here b/c otherwise it's a warning for eslint that error is never used
    }, [processingType?.label]);

    const handleTabChange = (index: number) => {
        setProcessingType(processingOptions.find(x => x.value == index));
    };

    const updateStudentAndOpenModal = (studentInfo: Student, callback: () => void) => {
        setSelectedStudent(studentInfo);
        callback();
    };

    const updateStudentAndOpenNoteModal = (studentInfo: Student, callback: () => void) => {
        setSelectedStudent(studentInfo);
        callback();
    };
    //type FilterType = "Resolved" | "Unresolved" | "NeedDate" | "Clear" | "StudentName" | "StudentId";
    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        let updatedRequirements: StudentRequirement[] = [...unfilteredRequirements]; // Start with all requirements
        // Apply filters in sequence
        filterTypes.forEach((type) => {
            switch (type) {
                case "Resolved":
                    updatedRequirements = updatedRequirements.filter((req) =>
                        req.needs.some((x) => x.uploaded)
                    );
                    break;
                case "Unresolved":
                    updatedRequirements = updatedRequirements.filter((req) =>
                        req.needs.some((x) => !x.uploaded)
                    );
                    break;
                case "NeedDate":
                    if (dateFilter !== '') {
                        const specificDate = new Date(dateFilter); // The date entered by the user
                        const dateParameter = getDateFilterValue(dateFilter); // The date value based on predefined filters
                        if (dateParameter) {
                            // If a predefined date filter (e.g., "One Week") is selected, filter by that range
                            updatedRequirements = updatedRequirements.filter((req) =>
                                req.needs.some((x) => new Date(x.needDate).getTime() >= dateParameter.getTime())
                            );
                        } else {
                            // If no predefined filter, filter by exact date
                            updatedRequirements = updatedRequirements.filter((req) =>
                                req.needs.some((x) => new Date(x.needDate).getTime() === specificDate.getTime())
                            );
                        }
                        updatedRequirements = updatedRequirements.sort((a, b) => {
                            const dateA = new Date(a.needs[0].needDate);
                            const dateB = new Date(b.needs[0].needDate);
                            return dateB.getTime() - dateA.getTime(); // Compare timestamps
                        });
                    }
                    break;
                case "ResponseDate":
                    if (dateFilter !== '') {
                        const specificDate = new Date(dateFilter); // The date entered by the user
                        const dateParameter = getDateFilterValue(dateFilter); // The date value based on predefined filters
                        if (dateParameter) {
                            // If a predefined date filter (e.g., "One Week") is selected, filter by that range
                            updatedRequirements = updatedRequirements.filter((req) =>
                                req.needs.some((x) => new Date(x.latestNeedResponseDate).getTime() >= dateParameter.getTime())
                            );
                        } else {
                            // If no predefined filter, filter by exact date
                            updatedRequirements = updatedRequirements.filter((req) =>
                                req.needs.some((x) => new Date(x.latestNeedResponseDate).getTime() === specificDate.getTime())
                            );
                        }
                        updatedRequirements = updatedRequirements.sort((a, b) => {
                            const dateA = new Date(a.needs[0].latestNeedResponseDate);
                            const dateB = new Date(b.needs[0].latestNeedResponseDate);
                            return dateB.getTime() - dateA.getTime(); // Compare timestamps
                        });
                    }
                    break;
                case "Clear":
                    updatedRequirements = [...unfilteredRequirements]; // Reset to unfiltered state
                    break;
            }
        });
        // Apply sorting logic after all filters
        filterTypes.forEach((type) => {
            switch (type) {
                case "StudentName":
                    updatedRequirements = updatedRequirements.sort((a, b) =>
                        a.studentName.localeCompare(b.studentName)
                    );
                    break;
                case "StudentId":
                    updatedRequirements = updatedRequirements.sort((a, b) =>
                        b.externalStudentId - a.externalStudentId
                    );
                    break;
                case "NeedDate":
                    updatedRequirements = updatedRequirements.sort((a, b) => {
                        const dateA = new Date(a.needs[0].needDate);
                        const dateB = new Date(b.needs[0].needDate);
                        return dateB.getTime() - dateA.getTime(); // Compare timestamps
                    });
                    break;
            }
        });
        setRequirements(updatedRequirements); // Update the final filtered and sorted list
        setLoading(false);
    };
    // Helper function to determine date filter value
    const getDateFilterValue = (filter: string): Date | null => {
        let dateParameter = new Date();
        switch (filter) {
            case "One Day":
                dateParameter = createDate(-1, 0, 0);
                break;
            case "Three Days":
                dateParameter = createDate(-3, 0, 0);
                break;
            case "One Week":
                dateParameter = createDate(-7, 0, 0);
                break;
            case "Two Weeks":
                dateParameter = createDate(-14, 0, 0);
                break;
            case "One Month":
                dateParameter = createDate(0, -1, 0);
                break;
            case "Two Months":
                dateParameter = createDate(0, -2, 0);
                break;
            case "Six Months":
                dateParameter = createDate(0, -6, 0);
                break;
            case "One Year":
                dateParameter = createDate(0, 0, -1);
                break;
            default:
                return null;
        }
        return dateParameter;
    };
    // Function to create a date based on the given parameters (days, months, years)
    function createDate(days: number, months: number, years: number): Date {
        const today = new Date();
        today.setDate(today.getDate() + days);
        today.setMonth(today.getMonth() + months);
        today.setFullYear(today.getFullYear() + years);
        return today;
    }
    //function createDate(days: number, months: number, years: number): Date {
    //    const date = new Date();
    //    date.setDate(date.getDate() + days);
    //    date.setMonth(date.getMonth() + months);
    //    date.setFullYear(date.getFullYear() + years);
    //    return date;
    //}
    //const handleFilterModalClose = async () => {
    //    setOpenFilterModal(false);
    //    let updatedRequirements: StudentRequirement[] = [];
    //    filterTypes.forEach((type) => {
    //        switch (type) {

    //            case type = "Resolved":
    //                // eslint-disable-next-line no-case-declarations
    //                const resolvedRequirements: StudentRequirement[] = [];
    //                (unfilteredRequirements.map((req) => {
    //                    const resolvedNeeds = req.needs.filter(x => x.uploaded);
    //                    if (resolvedNeeds.length > 0) {
    //                        req.needs = resolvedNeeds;
    //                        resolvedRequirements.push(req);
    //                    }
    //                }));
    //                updatedRequirements = (resolvedRequirements);
    //                break;
    //            case type = "Unresolved":
    //                // eslint-disable-next-line no-case-declarations
    //                const unresolvedRequirements: StudentRequirement[] = [];
    //                (unfilteredRequirements.map((req) => {
    //                    const unresolvedNeeds = req.needs.filter(x => !x.uploaded);
    //                    if (unresolvedNeeds.length > 0) {
    //                        req.needs = unresolvedNeeds;
    //                        unresolvedRequirements.push(req);
    //                    }
    //                }));
    //                updatedRequirements =(unresolvedRequirements);
    //                break;
    //            case type = "NeedDate":
    //                if (dateFilter != '') {
    //                    const specificDate = new Date(dateFilter);
    //                    if (specificDate.toString() == "Invalid Date") {
    //                        let dateParameter = new Date();
    //                        const threeDaysAgo = createDate(-3, 0, 0);
    //                        const oneWeekAgo = createDate(-7, 0, 0);
    //                        const twoWeekAgo = createDate(-14, 0, 0);
    //                        const oneMonthAgo = createDate(0, -1, 0);
    //                        const twoMonthsAgo = createDate(0, -2, 0);
    //                        const sixMonthsAgo = createDate(0, -6, 0);
    //                        const oneYearAgo = createDate(0, 0, -1);
    //                        const dateFilteredRequirements: StudentRequirement[] = [];
    //                        switch (dateFilter) {
    //                            case "One Day":
    //                                dateParameter = new Date();
    //                                break;
    //                            case "Three Days":
    //                                dateParameter = threeDaysAgo;
    //                                break;
    //                            case "One Week":
    //                                dateParameter = oneWeekAgo;
    //                                break;
    //                            case "Two Weeks":
    //                                dateParameter = twoWeekAgo;
    //                                break;
    //                            case "One Month":
    //                                dateParameter = oneMonthAgo;
    //                                break;
    //                            case "Two Months":
    //                                dateParameter = twoMonthsAgo;
    //                                break;
    //                            case "Six Months":
    //                                dateParameter = sixMonthsAgo;
    //                                break;
    //                            case "One Year":
    //                                dateParameter = oneYearAgo;
    //                                break;
    //                        }
    //                        (updatedRequirements.map((req) => {
    //                            const filteredNeeds = req.needs.filter(x => new Date(x.needDate) >= dateParameter);
    //                            if (filteredNeeds.length > 0) {
    //                                req.needs = filteredNeeds;
    //                                dateFilteredRequirements.push(req);
    //                            }
    //                        }));
    //                        updatedRequirements = (dateFilteredRequirements);
    //                    }
    //                    else {
    //                        const dateFilteredRequirements: StudentRequirement[] = [];
    //                        (updatedRequirements.map((req) => {
    //                            const filteredNeeds = req.needs.filter(x => new Date(x.needDate).getDate() == specificDate.getDate());
    //                            if (filteredNeeds.length > 0) {
    //                                req.needs = filteredNeeds;
    //                                dateFilteredRequirements.push(req);
    //                            }
    //                        }));
    //                        updatedRequirements = (dateFilteredRequirements);
    //                    }
    //                }
    //           break;
    //           case type = "Clear":
    //                updatedRequirements = (unfilteredRequirements);
    //                break;
    //        }
    //    });
    //    filterTypes.forEach((type) => {
    //        switch (type) {
    //            case type = "StudentName":
    //                updatedRequirements = (updatedRequirements.sort((a, b) => a.studentName.localeCompare(b.studentName)));
    //                break;
    //            case type = "StudentId":
    //                updatedRequirements = (updatedRequirements.sort((a, b) => b.externalStudentId - (a.externalStudentId)));
    //                break;
    //            case type = "NeedDate":
    //                updatedRequirements = (updatedRequirements.sort((a, b) => {
    //                    const dateA = new Date(a.needs[0].needDate);
    //                    const dateB = new Date(b.needs[0].needDate);
    //                    return dateA.getTime() - dateB.getTime(); // Compare timestamps
    //            }));
    //                break;
    //        }
    //    });
    //    setRequirements(updatedRequirements);
    //    setLoading(false);
    //};
    return (
        <>

            {!selectedSchoolName && <Loader />}

            {selectedSchoolName && (
                <div>

                    <div className="pageHeader">Processing</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    </div>

                    {openUploadModal &&
                        <StudentUploadModal onClose={onUploadModalClose} studentName={selectedStudent?.studentName ?? ''}
                            studentId={selectedStudent?.studentId ?? 0} schoolName={selectedSchoolName?.toString() ?? 'unknown school'} schoolId={selectedSchoolId} need={openUploadModal} />}
                    {openUploadMultipleModal.length > 0 && <StudentUploadMultipleModal onClose={onUploadMultipleModalClose}
                        studentName={selectedStudent?.studentName ?? 'unknown student Name'}
                        studentId={selectedStudent?.studentId ?? 0}
                        schoolName={selectedSchoolName?.toString() ?? 'unknown school'}
                        schoolId={selectedSchoolId}
                        needs={openUploadMultipleModal} />}
                    {openNoteModal.length > 0 && <NoteModal onClose={onNoteModalClose} studentName={selectedStudent?.studentName ?? ''}
                        studentId={selectedStudent?.studentId ?? 0}
                        schoolName={selectedSchoolName?.toString() ?? 'unknown school'}
                        schoolId={selectedSchoolId ?? 0}
                        needs={openNoteModal} />}
                    {openFormsModal && <DownloadFormsModal onClose={() => setOpenFormsModal(false)} processingType={processingType?.label} />}
                    {openFilterModal && <FilterModal onClose={() => { handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} dateFilterType={dateFilter} setDateFilterFunction={setDateFilter} requirements={unfilteredRequirements} />}

                    <TableContainer sx={{ marginTop: '30px', width: '90%', maxWidth: '1400px', overflowX: 'auto', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)' }}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ border: '0px', textWrap: 'nowrap' }}>
                                        <Tabs onSelect={handleTabChange}>
                                            <TabList style={{ display: 'flex' }} >
                                                <Tab className='faTabColor' style={processingType?.value === 0 ? activeTabStyle : tabStyle}>Financial Aid</Tab>
                                                <Tab className='academicTabColor' style={processingType?.value === 1 ? activeTabStyle : tabStyle}>Academic</Tab>
                                                <Tab className='tuitionTabColor' style={processingType?.value === 2 ? activeTabStyle : tabStyle}>Tuition</Tab>
                                                <Tab className='viewAllTabColor' style={processingType?.value === 3 ? activeTabStyle : tabStyle}>View All</Tab>
                                            </TabList>
                                        </Tabs>

                                    </TableCell>
                                    <TableCell colSpan={1} sx={{ border: '0px', textAlign: 'right', paddingRight: '30px' }}>
                                        <Button onClick={() => setOpenFormsModal(true)} variant="contained" style={{ backgroundColor: '#495DDB' }}>Download Forms
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{
                                        border: '0px', paddingTop: '0px'
                                    }} >
                                        <SortButton><img onClick={() => { setOpenFilterModal(true) }} src={filterButtonIcon} alt="Filter Icon" /></SortButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ border: '0px' }} colSpan={5}>
                                        {requirements.map((requirement, index) => (
                                            <Box key={index} >
                                                <Typography sx={{ fontSize: 16, padding: 2, paddingBottom: 0, marginBottom: '0px' }} color="#253872" gutterBottom>
                                                    <strong>{requirement.studentName}</strong> #{requirement.externalStudentId}
                                                </Typography> <Box sx={{ minWidth: 275, padding: '14px', paddingTop: '4px' }}>
                                                    <Card sx={{ minWidth: 275, minHeight: '85px', display: 'flex' }} variant="outlined" >
                                                        <Item key={requirement.studentId} style={{ width: '90%', borderRadius: '0' }} variant="outlined">
                                                            <Stack paddingLeft='10px' paddingTop='10px' spacing={2}>
                                                                {requirement.needs.map((need, index) => (
                                                                    <NeedItem need={need} />

                                                                ))}

                                                            </Stack></Item>
                                                        {processingType?.value != 3 && <Item style={{ borderRadius: '0' }}>
                                                            <Box sx={{ display: 'flex', paddingLeft: '4px' }} >
                                                                <div onClick={() => {
                                                                    updateStudentAndOpenModal({
                                                                        studentName: requirement.studentName,
                                                                        studentId: requirement.externalStudentId
                                                                    }, () => setOpenUploadMultipleModal(requirement.needs));
                                                                }}
                                                                >
                                                                    <NeedUploadIcon />
                                                                </div>
                                                                <div style={{ paddingLeft: '11px' }} onClick={() => {
                                                                    updateStudentAndOpenNoteModal({
                                                                        studentName: requirement.studentName,
                                                                        studentId: requirement.externalStudentId
                                                                    }, () => setOpenNoteModal(requirement.needs));
                                                                }}
                                                                >
                                                                    <NeedCommentIcon />
                                                                </div>
                                                            </Box></Item>}

                                                    </Card></Box>
                                            </Box>

                                        ))}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>



                </div>
            )}
        </>
    );
};

export default ProcessingNew;

