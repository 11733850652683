import React, { useState } from 'react';
import { CompleteProjectBodyCell, IncompleteProjectBodyCell, OverdueProjectBodyCell, ToDoProjectBodyCell, UploadedProjectBodyCell } from './ProjectsStyles';



type Props = {
    projectDescription: string;
    status: string;

}
const formatTaskDescription = (taskDescription: string) => {
    let taskDescriptionUpdated = taskDescription;

    if (taskDescription.includes("IPEDS")) {
        taskDescriptionUpdated = taskDescriptionUpdated.replace(/\b(opens|closes)\b/, '').trim();
    }
    return taskDescriptionUpdated;
};

const ProjectCell = ({ projectDescription, status }: Props) => {
    if (status == 'Complete') {


        return (
            <CompleteProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>   {formatTaskDescription( projectDescription)}</div>
            </CompleteProjectBodyCell>
        );
    }
    else if (status == 'Incomplete') {


        return (
            <IncompleteProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>      {formatTaskDescription(projectDescription)}</div>
            </IncompleteProjectBodyCell>
        );
    }
    else if (status == 'Overdue') {


        return (
            <OverdueProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>    {formatTaskDescription(projectDescription)}</div>
            </OverdueProjectBodyCell>
        );
    }
    else if (status == 'Uploaded') {


        return (
            <UploadedProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowX: 'auto' }}>  {formatTaskDescription(projectDescription)}</div> 
            </UploadedProjectBodyCell>
        );
    }
    else {//ToDo need type
        return (
            <ToDoProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>   {formatTaskDescription(projectDescription)}</div>
            </ToDoProjectBodyCell>
        );
    }
};
export default ProjectCell;
