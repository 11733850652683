import React, { useState } from 'react';
import styled from '@emotion/styled';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import axios from 'axios';
import saveAs from 'file-saver';
import { useToken } from '../../useToken';
import { API_BASE_URL } from '../../urlConfig';
import { IconButton } from '@mui/material';


type Props = {
    fileName: string;
    filePath: string;
    studentId: number;
    schoolId: number;
    folderCategory: string;
    blob?: Blob;
   }


const Wrapper = styled.div`
`;



const UploadText = styled.div`
color: #7f96ff;
font-size:12px; 

`;



const FileDownloadIcon = ({ blob, fileName,filePath, studentId, schoolId, folderCategory }: Props) => {

    const [error, setError] = useState('');
    const getToken = useToken();

    const handleFormDownload = async () => {
        try {
            logDownloadStudentFolder(fileName,filePath, studentId, schoolId, folderCategory);
            if (blob != null) {            
                saveAs(blob, fileName);
            }
            
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };
   
    const logDownloadStudentFolder = async (fileName: string, filePath: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Logger/addDownloadStudentFileLog`, {
            FilePath: filePath,
            FileName: fileName,
            StudentId: studentId.toString(),
            SchoolId: schoolId.toString(),
            FolderCategory: folderCategory
        }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                setError('');
            })
            .catch(error => {
                console.log(error?.response?.data);
                alert("Error while logging download");
            });
    };



    return (
        <Wrapper>{/*disabled={fileName.includes('.pdf') ? false:true}*/}
            <IconButton  onClick={() => (handleFormDownload())} style={{ width: '40px', height: '40px', marginTop: '5px', marginLeft: '5px' }}> <img
                src={downloadIcon}
                style={{ width: '40px', height: '40px' }}

            /></IconButton>
            <UploadText>Download</UploadText>
        </Wrapper>
    );
};
export default FileDownloadIcon;
