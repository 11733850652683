import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import '../../styles.css';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ViewAllButton } from '../ViewAllButton';
import axios from 'axios';
import { useToken } from '../../useToken';
import { useAppSelector } from '../../store';
import { Notification } from '../../globalTypes/objects';
import NotificationItem from './NotificationItem';
import { API_BASE_URL } from '../../urlConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: 'black',
}));

const NotificationsCard = () => {
    const schoolState = useAppSelector((state) => state.school);
    const selectedSchoolId = schoolState.school.id;
    const [notifications, setNotifications] = useState<Notification[] | null>(null); // Set initial state to null
    const [error, setError] = useState<string>('');
    const [notificationCleared, setNotificationCleared] = useState<number | null>(null); // Type for consistency
    const [loading, setLoading] = useState<boolean>(false); // Add loading state
    const getToken = useToken();

    const getNotifications = async () => {
        setLoading(true); // Start loading
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Notification/getNotifications/${selectedSchoolId}`, {
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setNotifications(data); // Set fetched data
        } catch (error: any) {
            setError(error.message); // Handle error
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        getNotifications().catch(error => {
            setError('Error getting notifications: ' + error);
        });
    }, [selectedSchoolId, notificationCleared]);

    return (
        <>
            <div className="dashboardHeader">Notifications</div>
            <div className={`dashboardBody ${loading ? 'loading' : ''}`}> {/* Apply loading class */}
                <TableContainer sx={{
                    width: '100%', minHeight: '320px', maxHeight: '320px', overflowY: 'auto', padding: '0px', background: 'white',
                }}>
                    <Table>
                        <TableBody>
                            {notifications && notifications.length > 0 ? (
                                notifications.map(notice => (
                                    <TableRow key={notice.notificationId}>
                                        <StyledTableCell>
                                            <NotificationItem 
                                                noticeText={notice.notificationText ?? ''}
                                                notificationId={notice.notificationId}
                                                notificationDate={notice.dateCreated}
                                                setNotificationCleared={setNotificationCleared}
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell>No New Notifications!</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default NotificationsCard;
