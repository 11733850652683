import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import axios from 'axios';
import SubmitButton from './SubmitButton';
import { useToken } from "../useToken";
/*import { Button } from './Button';*/
import Button from '@mui/material/Button';
import { API_BASE_URL } from '../urlConfig';
import { ConfirmText } from './ConfirmText';
import { PDFDocument } from 'pdf-lib';
import LoadingButton from '@mui/lab/LoadingButton';
import Loader from './Loader';
import { CircularProgress } from '@mui/material';
interface Props {
    uploadPath: string;
    onSuccess: () => void;
    acceptedFileTypes: Accept;
    loading: boolean;
    setLoading: any;
}
const UploadMultipleFiles = ({ uploadPath, onSuccess, acceptedFileTypes, loading, setLoading }: Props) => {
    // const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    //   const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const dropzoneStyles: React.CSSProperties = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };
    //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setResponse('');
        // setSelectedFile(acceptedFiles[0]);
        setSelectedFiles(acceptedFiles);
    }, []);
    const getToken = useToken();
    const handleUploadOld = async () => {
        const localToken = await getToken();
        setLoading(true);
        if (selectedFiles) {
            selectedFiles.forEach((selectedFile) => {
                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);
                axios.post(`${API_BASE_URL}/${uploadPath}`, formData, {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    },
                })
                    .then(() => {
                        onSuccess();
                        setLoading(false);
                    })
                    .catch(error => {
                        setResponse(error.response.data);
                        setLoading(false);
                    })
            });

        }
    };
    const handleUpload = async () => {
        const localToken = await getToken();
        setLoading(true);
        if (selectedFiles) {
            // Loop through selected files
            for (const selectedFile of selectedFiles) {
                const fileSizeKB = selectedFile.size / 1024;  // Convert file size to KB
                const formData = new FormData();
                if (fileSizeKB > 50000 && selectedFile.type == 'application/pdf') {  // Check if the file is larger than 50,000 KB
                    try {
                        const pdfBytes = await selectedFile.arrayBuffer();
                        const pdfDoc = await PDFDocument.load(pdfBytes);
                        // Determine how many smaller PDFs we need to create
                        const totalPages = pdfDoc.getPages().length;
                        const maxPagesPerFile = 10; // Adjust based on how many pages you want per split PDF
                        const numOfSplits = Math.ceil(totalPages / maxPagesPerFile);
                        // Split and upload each part
                        for (let i = 0; i < numOfSplits; i++) {
                            const splitDoc = await PDFDocument.create();
                            const startPage = i * maxPagesPerFile;
                            const endPage = Math.min(startPage + maxPagesPerFile, totalPages);
                            // Extract the pages for the current split
                            const pagesToAdd = await splitDoc.copyPages(pdfDoc, Array.from({ length: endPage - startPage }, (_, index) => startPage + index));
                            pagesToAdd.forEach(page => splitDoc.addPage(page));
                            // Prepare the new split PDF for upload
                            const splitPdfBytes = await splitDoc.save();
                            const splitFile = new File([splitPdfBytes], `${i + 1}_${selectedFile.name}`, {
                                type: 'application/pdf',
                            });
                            // Upload the split PDF
                            const splitFormData = new FormData();
                            splitFormData.append('file', splitFile, splitFile.name);
                            try {
                                await axios.post(`${API_BASE_URL}/${uploadPath}`, splitFormData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': `Bearer ${localToken?.accessToken}`,
                                    },
                                });

                            } catch (error) {
                                setResponse(error.response.data);
                                setLoading(false);
                            }
                        }
                        onSuccess();
                    } catch (error) {
                        console.error('Error splitting PDF:', error);
                        setLoading(false);
                    }
                } else {
                    // Upload file directly if size is less than 50,000 KB
                    formData.append('file', selectedFile, selectedFile.name);
                    try {
                        await axios.post(`${API_BASE_URL}/${uploadPath}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${localToken?.accessToken}`,
                            },
                        });
                        onSuccess();
                    } catch (error) {
                        setResponse(error.response.data);
                        setLoading(false);
                    }
                }
            }
            setLoading(false);
        }
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: acceptedFileTypes
    });
    return (
        <div>
            <div>
                <div {...getRootProps()}
                    style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <div>
                        Drag and drop the files here or click to select files
                        {selectedFiles && (
                            <span>
                                <div>Selected File(s):{selectedFiles.map(file => (
                                    <div key={file.size}>  {file.name}</div>
                                ))} </div>
                            </span>
                        )}
                        {response && (
                            <span>
                                <p style={response == "Success" ? { color: 'green' } : { color: 'red' }}>Response: {response}</p>
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {selectedFiles && selectedFiles.length > 0 && (
                <div  style={{ paddingTop: '10px' }}>
                    <ConfirmText />
                    <Button onClick={handleUpload} variant="contained" disabled={loading} style={{ backgroundColor: '#253872' }}>

                     Submit

                    </Button>

                </div>
            )}
        </div>
    );
};
export default UploadMultipleFiles;