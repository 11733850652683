import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import saveAs from 'file-saver';
import { useToken } from '../useToken';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint } from "react-to-print";
import printJS from 'print-js';
import { API_BASE_URL } from '../urlConfig';
import { IconButton } from '@mui/material';
import printIcon from '../Images/printIcon.svg';

type Props = {
    fileName: string;
    filePath: string;
    studentId: number;
    schoolId: number;
    folderCategory: string;
    blob?: Blob;
}


const Wrapper = styled.div`
margin-left:25px;
`;



const Icon = styled.img`
width: 40px;
max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 8px;
`;

const UploadText = styled.div`
color: #7f96ff;
font-size:12px; 
margin-left:3px;

`;

const Elipse = styled.div`
 width:40px;
 height:40px;
border-radius: 50%;
background-color:#f6f8fe;
`;



const PrintIconButton = ({ blob, fileName, filePath, studentId, schoolId, folderCategory }: Props) => {
    const [error, setError] = useState('');
    const getToken = useToken();

    const handleFormPrint = async () => {
        try {
            logPrintSchoolFile(fileName, filePath, studentId, schoolId, folderCategory);
            if (blob != null) {
                const uri = URL.createObjectURL(blob);
                printJS({ printable: uri });
            }

        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logPrintSchoolFile = async (fileName: string, filePath: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Logger/addPrintSchoolFileLog`, {
            FilePath: filePath,
            FileName: fileName,
            StudentId: studentId.toString(),
            SchoolId: schoolId.toString(),
            FolderCategory: folderCategory
        }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                setError('');
            })
            .catch(error => {
                console.log(error?.response?.data);
                alert("Error while logging print");
            });
    };

    return (<Wrapper>
        <IconButton disabled={fileName.includes('.pdf') ? false : true} onClick={() => (handleFormPrint())} style={{ width: '40px', height: '40px', marginTop: '5px' }}> <img
            src={printIcon}
            style={{ width: '40px', height: '40px' }}

        /></IconButton>
        <UploadText>Print</UploadText>
    </Wrapper>

    );
};
export default PrintIconButton;
