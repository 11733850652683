import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import { Form } from '../../globalTypes/objects';
import saveAs from 'file-saver';
import { useToken } from "../../useToken";
import DownloadIcon from '../Processing/DownloadIcon';
import greenCheckIcon from '../../Images/greenCheckIcon.svg';
import { API_BASE_URL } from '../../urlConfig';
import { CommentDate, IncompleteComment, CommentCreatedBy } from '../Processing/ProcessingStyles';
import momentTz from 'moment-timezone';
import garbageIcon from '../../Images/blackGarbageIcon.svg';

const OuterWrap = styled.div`
  border-radius: 5px;
  border: 2px solid #b5b5b8;
  padding: 40px 24px;
  width: 250px;
`;

const InnerStyles = styled.div`
 display:flex;
 height: 45px;
 margin-top: 8px;
 margin-bottom: 8px;
 align-items:center;
 cursor:pointer;
 border-radius: 4px;
background: var(--Light-Blue-Background, rgba(131, 151, 248, 0.07));
`


const ModalHeader = styled.div`
background-color:#8397F826;
font-weight:700;
color:#253872;
font-size:16px;
align-items:center;

`;



type Props = {
    fileId: number;
    schoolId: number;
}

const DownloadDocuments = ({ fileId, schoolId }: Props) => {
    const [documents, setDocuments] = useState<Form[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getDocuments = async () => {
        try {
            const localToken = await getToken();
            const url = API_BASE_URL;
            const { data } = await axios.get(`${API_BASE_URL}/api/Document/getAnnualSchoolFilesDocuments/${fileId}/${schoolId}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDocuments(data);
        } catch (error: any) {
            console.log(error.message);
            //setError(error.message || 'An unknown error occurred');
        }
    };

    const handleDocumentDownload = async (form: Form) => {
        try {
            const localToken = await getToken();
            //   const baseUrl = process.env.REACT_APP_API_BASE_URL;
            logDownloadDocument(form.id);
            const response = await axios.get(`${API_BASE_URL}/api/Document/DownloadAnnualSchoolFilesSchoolSubmittedDocument/${form.id}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });


            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            saveAs(blob, form.fileName);
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logDownloadDocument = async (formId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadSchoolSubmittedAnnualSchoolFileLog/${formId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                setError(error?.response?.data);

            })
    };
    const deleteDocument = async (documentId: number) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/document/DeleteAnnualSchoolFile`, { Id: documentId }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                //  console.log(response);
                //  setSuccess(true);
                getDocuments();
                setLoading(false);

            })
            .catch(error => {
                // setResponse(error?.response?.data);
                setError(error?.response);
                setLoading(false);
            })
    };
    momentTz.locale('en-us');
    // momentTz.tz.setDefault("America/New_York"); 
    const formatDate = (dateString: string) => {
        const date = momentTz.utc(dateString);//.subtract(4, 'hours');
        const estDate = date.tz('America/New_York');
        const formatedDate = estDate.format("MMM DD h:mm a");
        return formatedDate;
    };

    useEffect(() => {
        getDocuments()
    }, []);

    return (
        <div style={{ marginTop: '34px' }}>
            {
                documents.map(
                    (document) =>
                        <div key={document.id} style={{ display: 'flex' }}>
                            {/*    <InnerStyles onClick={() => handleDocumentDownload(document)}>*/}
                            {/*    <div style={{ width: '15%' }}> <DownloadIcon /></div>*/}
                            {/*    <div style={{ width: '75%', paddingTop: '5px', paddingLeft: '5px', fontSize: '13px', textAlign: 'left' }}>{document.fileName} {document.uploadedBy} {document.uploadedAt}</div>*/}
                            {/*</InnerStyles>*/}
                            <IncompleteComment style={{ width: '100%' }} >
                                <div style={{ width: '10%', marginTop: '10px' }}onClick={() => handleDocumentDownload(document)}> <DownloadIcon /></div>
                                <div
                                    style={{
                                        width: '45%', overflowX: 'clip', wordWrap: 'break-word', marginTop: '10px', paddingLeft: '5px', fontSize: '13px',
                                        textAlign: 'left', color: 'black'
                                    }}>
                                    {document.fileName}
                                </div>
                                <div style={{ width: '10%', marginTop: '15px', marginLeft:'8px',fontSize:'12px' }}>{document.approved && <i>Approved</i>} </div>
                                <div style={{ width: '5%', marginTop: '15px', marginLeft: '25px' }}> <img style={{ cursor: 'pointer', paddingLeft: '8px' }} title="Delete Document" onClick={() => { deleteDocument(document.id) }} height='15px' width='15px' src={garbageIcon} /></div>
                                <div style={{ width: '20%', justifyContent: 'end' }}>
                                                                       <CommentDate> <CommentCreatedBy> {document.uploadedBy}</CommentCreatedBy>{document.uploadedAt ? formatDate(document.uploadedAt) : ''}</CommentDate>
                                </div>
                                </IncompleteComment>

                        </div>
                )
            }
            {error != '' && <div style={{ color: 'red', marginLeft: '20px' }}>{error}</div>}
        </div>


        //<OuterWrap>

        //</OuterWrap>
    );
};

export default DownloadDocuments;
